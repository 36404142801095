import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { PagedResult, Condition } from '../../models';
import { IBaseService } from '../BaseService';


export interface IConditionService extends IBaseService<Condition, number> {
	getMultiple(ids: number[]): Promise<Condition[]>;
}


@injectable()
export class ConditionService implements IConditionService {

	private cache: Promise<Condition[]>;

	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }

	public get(id: number): Promise<Condition> {
		const url = `${this.settings.baseUrl}${this.settings.conditionBaseUrl}/${id}`;
		return this.httpClient.get<Condition>(url);
	}

	public getMultiple(ids: number[]): Promise<Condition[]> {
		const url = `${this.settings.baseUrl}${this.settings.conditionBaseUrl}/multiple/${ids.join(',')}`;
		return this.httpClient.get<Condition[]>(url);
	}

	public page(pageIndex: number, pageSize: number): Promise<PagedResult<Condition>> {
		const url = `${this.settings.baseUrl}${this.settings.conditionBaseUrl}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Condition>>(url);
	}

	public list(): Promise<Condition[]> {
		const url = `${this.settings.baseUrl}${this.settings.conditionBaseUrl}`;
		if (!this.cache) { this.cache = this.httpClient.get<Condition[]>(url); }
		return this.cache;
	}

	public save(model: Condition): Promise<Condition> {
		this.cache = null; // INVALIDATE CACHE
		const payload = {
			name: model.name,
			description: model.description,
			id: model.id,
			sortBy: model.sortBy != null ? parseInt(model.sortBy.toString(), null) : 0,
		};
		let url = `${this.settings.baseUrl}${this.settings.conditionBaseUrl}`;
		if (model.id !== null && model.id !== undefined) {
			url += `/${model.id}`;
			return this.httpClient.put(url, payload);
		}
		return this.httpClient.post(url, payload);
	}

	public delete(id: number): Promise<any> {
		this.cache = null; // INVALIDATE CACHE
		const url = `${this.settings.baseUrl}${this.settings.conditionBaseUrl}/${id}`;
		return this.httpClient.delete(url);
	}

}
