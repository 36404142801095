import { Vue } from 'vue-property-decorator';
import Vuetify from 'vuetify/lib/framework';
import VuetifyConfirm from 'vuetify-confirm';
import 'vuetify/dist/vuetify.min.css';


export default () => {
	const vuetifyOptions = { };
	Vue.use(Vuetify);
	const vuetify = new Vuetify(vuetifyOptions);
	Vue.use(VuetifyConfirm, { vuetify });
	return vuetify;
};


