"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Setting = void 0;
/**
 * Represents a single setting that can be stored in the database
 */
var Setting = /** @class */ (function () {
    /** Creates an instance of this object. */
    function Setting(init) {
        Object.assign(this, init);
    }
    return Setting;
}());
exports.Setting = Setting;
