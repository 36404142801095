import { container } from 'inversify-props';
import {
	IArtistAccountService, ArtistAccountService,
	IArtistImageService, ArtistImageService,
	IArtistService, ArtistService,
	IProAccountService, ProAccountService,
	IProImageService, ProImageService,
	IProService, ProService,
	ICompanyAccountService, CompanyAccountService,
	ICompanyImageService, CompanyImageService,
	ICompanyService, CompanyService,
	IRelationService, RelationService,
	IStanceService, StanceService,
} from '../services/industry';

export default () => {
	container.bind<IArtistAccountService>('IArtistAccountService').to(ArtistAccountService);
	container.bind<IArtistImageService>('IArtistImageService').to(ArtistImageService);
	container.bind<IArtistService>('IArtistService').to(ArtistService);

	container.bind<IProAccountService>('IProAccountService').to(ProAccountService);
	container.bind<IProImageService>('IProImageService').to(ProImageService);
	container.bind<IProService>('IProService').to(ProService);

	container.bind<ICompanyAccountService>('ICompanyAccountService').to(CompanyAccountService);
	container.bind<ICompanyImageService>('ICompanyImageService').to(CompanyImageService);
	container.bind<ICompanyService>('ICompanyService').to(CompanyService);

	container.bind<IRelationService>('IRelationService').to(RelationService);
	container.bind<IStanceService>('IStanceService').to(StanceService);
};
