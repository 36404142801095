"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArtistImageService = void 0;
var inversify_props_1 = require("inversify-props");
var ArtistImageService = /** @class */ (function () {
    function ArtistImageService(httpClient, logger, settings) {
        this.httpClient = httpClient;
        this.logger = logger;
        this.settings = settings;
    }
    ArtistImageService.prototype.get = function (artistId, imageId) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/" + artistId + "/images/" + imageId;
        return this.httpClient.get(url);
    };
    ArtistImageService.prototype.list = function (artistId) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/" + artistId + "/images";
        return this.httpClient.get(url);
    };
    ArtistImageService.prototype.page = function (artistId, pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/" + artistId + "/images/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    ArtistImageService.prototype.save = function (image) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/" + image.artistId + "/images";
        if (image.id !== null && image.id !== undefined && image.id !== '') {
            url += "/" + image.id;
            return this.httpClient.put(url, image);
        }
        return this.httpClient.post(url, image);
    };
    ArtistImageService.prototype.delete = function (artistId, imageId) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/" + artistId + "/images/" + imageId;
        return this.httpClient.delete(url);
    };
    ArtistImageService.prototype.revert = function (artistId) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/" + artistId + "/images/revert";
        return this.httpClient.post(url, null);
    };
    ArtistImageService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('IHttpClient')),
        __param(1, inversify_props_1.inject('ILogger')),
        __param(2, inversify_props_1.inject('ISettings'))
    ], ArtistImageService);
    return ArtistImageService;
}());
exports.ArtistImageService = ArtistImageService;
