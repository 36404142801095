"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Gender = void 0;
/** Represents the gender of a user or person on this site */
var Gender = /** @class */ (function () {
    function Gender(init) {
        Object.assign(this, init);
    }
    return Gender;
}());
exports.Gender = Gender;
