"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelationByYear = void 0;
/** Represents a time-boxed relationship between two objects */
var RelationByYear = /** @class */ (function () {
    /** Creates a new instance of this object */
    function RelationByYear(init) {
        /** The subjects who are apart of this relation during this year */
        this.subjects = [];
        Object.assign(this, init);
    }
    return RelationByYear;
}());
exports.RelationByYear = RelationByYear;
