"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SearchCollection = void 0;
/** Represents the results that come back from a global search. */
var SearchCollection = /** @class */ (function () {
    function SearchCollection() {
    }
    return SearchCollection;
}());
exports.SearchCollection = SearchCollection;
