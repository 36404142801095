"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Condition = void 0;
/** Represents the condition of a product */
var Condition = /** @class */ (function () {
    function Condition(init) {
        Object.assign(this, init);
    }
    return Condition;
}());
exports.Condition = Condition;
