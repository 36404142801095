import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { PagedResult, Audit } from '../../models';


export interface IAuditService {
	list(tableName: string, id: string): Promise<Audit[]>;
	page(tableName: string, id: string, pageIndex: number, pageSize: number): Promise<PagedResult<Audit>>;
	byUser(userId: string, pageIndex: number, pageSize: number): Promise<PagedResult<Audit>>;
}


@injectable()
export class AuditService implements IAuditService {

	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }


	public page(tableName: string, id: string, pageIndex: number, pageSize: number): Promise<PagedResult<Audit>> {
		const url = `${this.settings.baseUrl}${this.settings.auditBaseUrl}/${tableName}/${id}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Audit>>(url);
	}

	public list(tableName: string, id: string): Promise<Audit[]> {
		const url = `${this.settings.baseUrl}${this.settings.auditBaseUrl}/${tableName}/${id}`;
		return this.httpClient.get<Audit[]>(url);
	}

	public byUser(userId: string, pageIndex: number, pageSize: number): Promise<PagedResult<Audit>> {
		const url = `${this.settings.baseUrl}${this.settings.auditBaseUrl}/by-user/${userId}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Audit>>(url);
	}

}
