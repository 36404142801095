import VueRouter from 'vue-router';
import { container } from 'inversify-props';
import { Vue } from 'vue-property-decorator';
import { IHttpClient, IScrollService } from '@shared/services';
import deckRoutes from './deck.routes';
import artistRoutes from './artist.routes';
import blogRoutes from './blog.routes';
import categoryRoutes from './categories.routes';
import commonRoutes from './common.routes';
import companyRoutes from './company.routes';
import proRoutes from './pro.routes';
import firebase from 'firebase';

Vue.use(VueRouter);

export function router() {
	const r = new VueRouter({
		mode: 'history',
		routes: [
			{ path: '', component: () => import('@client/views/common/Layout.vue'), children: [
				...commonRoutes(),
				...artistRoutes(),
				...blogRoutes(),
				...categoryRoutes(),
				...companyRoutes(),
				...proRoutes(),
				...deckRoutes(),
			], meta: { requiresAuth: true }},
			{ path: '/login', name: 'login', component: () => import('@client/views/auth/Login.vue') },
			{ path: '/register', name: 'register', component: () => import('@client/views/auth/Register.vue') },
			{ path: '/forgot', name: 'forgot-password', component: () => import('@client/views/auth/ForgotPassword.vue') },
			{ path: '*', component: () => import('@client/views/common/404.vue') },
		],
	});
	r.beforeEach(async (to, from, next) => {
		// AUTOMATICALLY SCROLL TO THE TOP OF THE PAGE ON EACH ROUTE CHANGE
		const scroller = container.get('IScrollService') as IScrollService;
		const http = container.get('IHttpClient') as IHttpClient;
		scroller.scrollToTop();

		// CHECK TO SEE IF THIS ROUTE REQUIRES AUTHENTICATION
		if (to.matched.some((record) => record.meta.requiresAuth)) {
			// ROUTE REQUIRES AUTHORIZATION, GET USER FROM FIREBASE FIRST BEFORE SERVING THE ROUTE
			const user = await getCurrentUser();
			if (!user) {
				next({ name: 'login',  query: { redirect: to.fullPath, noauth: 'true' }});
			} else {
				const token = await user.getIdToken();
				http.addAuthHeaders(token);
				next();
			}
		} else {
			next();
		}
	});
	return r;
}

/** Handles getting the current logged in user from firebase. */
const getCurrentUser = (): Promise<firebase.User> => {
	return new Promise((resolve, reject) => {
		const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
			unsubscribe();
			resolve(user);
		}, reject);
	});
};
