"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ContactReason = void 0;
var ContactReason = /** @class */ (function () {
    function ContactReason(init) {
        Object.assign(this, init);
    }
    return ContactReason;
}());
exports.ContactReason = ContactReason;
