
export class ClaimRequest {
	public invitationId: string;
	public displayName: string;
	public email: string;

	constructor(init?: Partial<ClaimRequest>) {
		Object.assign(this, init);
	}

}
