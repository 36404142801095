import { Tag } from './Tag';

export class BlogTag extends Tag {

	constructor(init?: Partial<BlogTag>) {
		super(init);
		Object.assign(this, init);
	}

}
