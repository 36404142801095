"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Price = void 0;
/** Represents a recorded price of a product on the website */
var Price = /** @class */ (function () {
    function Price(init) {
        Object.assign(this, init);
    }
    return Price;
}());
exports.Price = Price;
