import { User } from '../auth/User';
import { BlogTag } from './BlogTag';

export class BlogEntry {
	public id: string;
	public title: string;
	public body: string;
	public slug: string;
	public publishDate: Date;
	public summary: string;
	public isPublished: boolean;
	public createdBy: User;
	public lastUpdatedBy: User;
	public createdDate: Date;
	public updatedDate: Date;
	public tags: BlogTag[] = [];

	constructor(init?: Partial<BlogEntry>) {
		Object.assign(this, init);
	}

}
