
import { AccountType } from './AccountType';
import { User } from '../auth/User';

export class Account {
	public id: string;
	public createdDate: Date;
	public updatedDate: Date;
	public value: string;
	public accountType: AccountType;
	public isEnabled: boolean;
	public createdBy: User;
	public lastUpdatedBy: User;
}
