"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanySearchRequest = void 0;
var inversify_props_1 = require("inversify-props");
var SearchRequest_1 = require("../common/SearchRequest");
var CompanySearchRequest = /** @class */ (function (_super) {
    __extends(CompanySearchRequest, _super);
    function CompanySearchRequest(init) {
        var _a, _b;
        var _this = _super.call(this, init) || this;
        _this.artistIds = [];
        _this.proIds = [];
        _this.artists = [];
        _this.pros = [];
        _this.isEnabled = null;
        _this.isPopular = null;
        if (!init) {
            return _this;
        }
        if (init.artistIds) {
            _this.artistIds = Array.isArray(init.artistIds)
                ? init.artistIds
                : init.artistIds.split(',');
        }
        if (init.proIds) {
            _this.proIds = Array.isArray(init.proIds)
                ? init.proIds
                : init.proIds.split(',');
        }
        if (init.startYear) {
            _this.startYear = parseInt(init.startYear, null);
        }
        if (init.endYear) {
            _this.endYear = parseInt(init.endYear, null);
        }
        if (init.isEnabled != null) {
            _this.isEnabled = (init.isEnabled.toString() === 'true');
        }
        if (init.isPopular != null) {
            _this.isPopular = (init.isPopular.toString() === 'true');
        }
        // INSTEAD OF GETTING THESE VALUES FROM THE DATABASE, TRY AND GET THEM FROM LOCAL STORAGE
        var json = _this.storage.retrieve('company-filter');
        if (!json) {
            return _this;
        }
        var prevFilter = JSON.parse(json);
        if (!prevFilter) {
            return _this;
        }
        if ((_a = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.artists) === null || _a === void 0 ? void 0 : _a.length) {
            var _loop_1 = function (id) {
                var found = prevFilter.artists.find(function (x) { return x.id === id; });
                if (found) {
                    this_1.artists.push(found);
                }
            };
            var this_1 = this;
            for (var _i = 0, _c = _this.artistIds; _i < _c.length; _i++) {
                var id = _c[_i];
                _loop_1(id);
            }
        }
        if ((_b = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.pros) === null || _b === void 0 ? void 0 : _b.length) {
            var _loop_2 = function (id) {
                var found = prevFilter.pros.find(function (x) { return x.id === id; });
                if (found) {
                    this_2.pros.push(found);
                }
            };
            var this_2 = this;
            for (var _d = 0, _e = _this.proIds; _d < _e.length; _d++) {
                var id = _e[_d];
                _loop_2(id);
            }
        }
        return _this;
    }
    CompanySearchRequest.prototype.collapse = function () {
        var response = _super.prototype.collapse.call(this);
        if (this.artistIds && this.artistIds.length) {
            response.artistIds = this.artistIds.join(',');
        }
        if (this.proIds && this.proIds.length) {
            response.proIds = this.proIds.join(',');
        }
        if (this.startYear) {
            response.startYear = this.startYear;
        }
        if (this.endYear) {
            response.endYear = this.endYear;
        }
        if (this.isEnabled != null) {
            response.isEnabled = this.isEnabled;
        }
        if (this.isPopular != null) {
            response.isPopular = this.isPopular;
        }
        return response;
    };
    CompanySearchRequest.prototype.clear = function () {
        _super.prototype.clear.call(this);
        this.artistIds = [];
        this.proIds = [];
        this.isEnabled = null;
        this.isPopular = null;
    };
    __decorate([
        inversify_props_1.inject('IStorage')
    ], CompanySearchRequest.prototype, "storage", void 0);
    return CompanySearchRequest;
}(SearchRequest_1.SearchRequest));
exports.CompanySearchRequest = CompanySearchRequest;
