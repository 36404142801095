import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { OverviewReport, DeckReport, ArtistReport, CompanyReport, ProReport, PagedResult, SubjectAggregate, Company } from '../../models';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
dayjs.extend(utc);

export interface IReportsService {

	overview(startDate: Date, endDate: Date): Promise<OverviewReport>;
	deckReport(startDate: Date, endDate: Date, limit: number): Promise<DeckReport>;
	artistReport(startDate: Date, endDate: Date, limit: number): Promise<ArtistReport>;
	proReport(startDate: Date, endDate: Date, limit: number): Promise<ProReport>;
	companyReport(startDate: Date, endDate: Date, limit: number): Promise<CompanyReport>;
	companiesMissingDeckImagesReport(pageIndex: number, pageSize: number): Promise<PagedResult<SubjectAggregate<Company>>>;
}

@injectable()
export class ReportsService implements IReportsService {

	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) {}


	public overview(startDate: Date, endDate: Date): Promise<OverviewReport> {
		const start = (dayjs(startDate) as any).utc().format('YYYY-MM-DD hh:mm:ss');
		const end = (dayjs(endDate) as any).utc().format('YYYY-MM-DD hh:mm:ss');
		const url = `${this.settings.baseUrl}/api/reports/overview?startDate=${start}&endDate=${end}`;
		return this.httpClient.get<OverviewReport>(url);
	}

	public deckReport(startDate: Date, endDate: Date, limit: number = 12): Promise<DeckReport> {
		const start = (dayjs(startDate) as any).utc().format('YYYY-MM-DD hh:mm:ss');
		const end = (dayjs(endDate) as any).utc().format('YYYY-MM-DD hh:mm:ss');
		const url = `${this.settings.baseUrl}/api/reports/decks?startDate=${start}&endDate=${end}&limit=${limit}`;
		return this.httpClient.get<DeckReport>(url);
	}

	public artistReport(startDate: Date, endDate: Date, limit: number = 12): Promise<ArtistReport> {
		const start = (dayjs(startDate) as any).utc().format('YYYY-MM-DD hh:mm:ss');
		const end = (dayjs(endDate) as any).utc().format('YYYY-MM-DD hh:mm:ss');
		const url = `${this.settings.baseUrl}/api/reports/artists?startDate=${start}&endDate=${end}&limit=${limit}`;
		return this.httpClient.get<ArtistReport>(url);
	}

	public proReport(startDate: Date, endDate: Date, limit: number = 12): Promise<ProReport> {
		const start = (dayjs(startDate) as any).utc().format('YYYY-MM-DD hh:mm:ss');
		const end = (dayjs(endDate) as any).utc().format('YYYY-MM-DD hh:mm:ss');
		const url = `${this.settings.baseUrl}/api/reports/pros?startDate=${start}&endDate=${end}&limit=${limit}`;
		return this.httpClient.get<ProReport>(url);
	}

	public companyReport(startDate: Date, endDate: Date, limit: number = 12): Promise<CompanyReport> {
		const start = (dayjs(startDate) as any).utc().format('YYYY-MM-DD hh:mm:ss');
		const end = (dayjs(endDate) as any).utc().format('YYYY-MM-DD hh:mm:ss');
		const url = `${this.settings.baseUrl}/api/reports/companies?startDate=${start}&endDate=${end}&limit=${limit}`;
		return this.httpClient.get<CompanyReport>(url);
	}

	public companiesMissingDeckImagesReport(pageIndex: number, pageSize: number): Promise<PagedResult<SubjectAggregate<Company>>> {
		const url = `${this.settings.baseUrl}/api/reports/companies/missing-deck-images/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<SubjectAggregate<Company>>>(url);
	}

}
