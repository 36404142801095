"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Technology = void 0;
var Technology = /** @class */ (function () {
    function Technology(init) {
        Object.assign(this, init);
    }
    return Technology;
}());
exports.Technology = Technology;
