"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Audit = void 0;
/** Represents a tracked modification of a record in the database. */
var Audit = /** @class */ (function () {
    function Audit() {
    }
    return Audit;
}());
exports.Audit = Audit;
