import Vue from 'vue';

Vue.filter('clamp', (text: string, limit: number) => {
	if (text === null) {
		return '';
	}
	if (typeof text === 'string' && text.length > limit) {
		text = text
			.replace('<', '&gt;')
			.replace('>', '$lt;')
			.replace('"', '&quot;');
		return `<span>${text.slice(0, limit)}...</span>`;
	}
	return text;
});
