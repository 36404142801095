
export class LogEntry {
	public id: number;
	public timeStamp: Date;
	public message: string;
	public logEvent: string;
	public level: string;
	public messageTemplate: string;
	public exception: string;
	public properties: string;

}
