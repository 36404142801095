import { inject } from 'inversify-props';
import { IStorage } from '../../services';
import { SearchRequest } from '../common/SearchRequest';
import { BlogTag } from './BlogTag';

export class BlogSearchRequest extends SearchRequest {

	@inject('IStorage')
	public storage: IStorage;

	public tagIds: number[];
	public tags: BlogTag[] = [];
	public startTime: string;
	public endTime: string;
	public slug: string;

	constructor(init?: any) {
		super(init);
		if (!init) { return; }
		if (init.tagIds) {
			if (Array.isArray(init.tagIds)) {
				this.tagIds = init.tagIds;
			} else {
				const tags = init.tagIds.split(',');
				this.tagIds = tags.map((x) => parseInt(x, null));
			}
		}
		if (init.startTime) { this.startTime = init.startTime; }
		if (init.endTime) { this.endTime = init.endTime; }

		// INSTEAD OF GETTING THESE VALUES FROM THE DATABASE, TRY AND GET THEM FROM LOCAL STORAGE
		const json = this.storage.retrieve('blog-filter');
		if (!json) { return; }

		const prevFilter = JSON.parse(json) as BlogSearchRequest;
		if (!prevFilter) { return; }

		if (prevFilter && prevFilter.tagIds && prevFilter.tagIds.length) {
			for (const id of prevFilter.tagIds) {
				const found = prevFilter.tags.find((x) => x.id === id);
				if (found) { this.tags.push(found); }
			}
		}
	}

	public collapse(): any {
		const response = super.collapse();
		if (this.tagIds?.length) { response.tagIds = this.tagIds.join(','); }
		if (this.startTime) { response.startTime = this.startTime; }
		if (this.endTime) { response.endTime = this.endTime; }
		return response;
	}

	public clear() {
		super.clear();
		this.tagIds = [];
		this.startTime = '';
		this.endTime = '';
		this.tags = [];
	}

}
