"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeckShape = void 0;
var DeckShape = /** @class */ (function () {
    function DeckShape(init) {
        Object.assign(this, init);
    }
    return DeckShape;
}());
exports.DeckShape = DeckShape;
