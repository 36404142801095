
export class Image {
	public id: string;
	public isDefault: boolean;
	public isEnabled: boolean;
	public isLinked: boolean;
	public caption: string;
	public altText: string;
	public mimeType: string;
	public url: string;
	public data: string;
	public attribution: string;
	public originalWidth: number;
	public originalHeight: number;
	public createdDate: Date;
	public updatedDate: Date;
}
