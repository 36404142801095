



















import { Component, Prop } from 'vue-property-decorator';
import { BaseView } from '../views/BaseView';

@Component({})
export default class Logo extends BaseView { 

	@Prop({ default: 30 })
	public height: number;

	@Prop({ default: '#CC0000' })
	public color: string;

}

