import 'reflect-metadata';
import { injectable } from 'inversify-props';

/** Defines a contract for all loggers in this application */
export interface ILogger {

	/**
	 * Logs a message to the console window
	 * @param message the message to log to the console window.
	 */
	log(message: string): void;

	/**
	 * Logs an error message to the console window
	 * @param message The message to log to the console window.
	 */
	error(message: any): void;

}

/** Default logger service for logging to the console window. */
@injectable()
export class LoggerService implements ILogger {


	/**
	 * Logs a message to the console window
	 * @params message the message to log to the console window.
	 */
	public log(message: string): void {
		// tslint:disable-next-line:no-console
		console.log(message);
	}

	/**
	 * Logs an error message to the console window
	 * @param message The message to log to the console window.
	 */
	public error(message: string): void {
		// tslint:disable-next-line:no-console
		console.error(message);
	}

}
