"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategoryImageRequest = void 0;
var CategoryImageRequest = /** @class */ (function () {
    function CategoryImageRequest(init) {
        this.data = '';
        Object.assign(this, init);
    }
    return CategoryImageRequest;
}());
exports.CategoryImageRequest = CategoryImageRequest;
