import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { User, PagedResult, UserSearchRequest, UserCreateRequest } from '../../models';

export interface IUserService {
	profile(): Promise<User>;
	get(id: string): Promise<User>;
	getMultiple(ids: string[]): Promise<User[]>;
	list(pageIndex: number, pageSize: number): Promise<PagedResult<User>>;
	autocomplete(crtieria: string): Promise<User[]>;
	search(request: UserSearchRequest): Promise<PagedResult<User>>;
	signup(request: UserCreateRequest): Promise<User>;
	save(model: User, inviteCode?: string): Promise<User>;
	delete(id: string): Promise<any>;
	resetPassword(email: string): Promise<any>;
	changePassword(token: string, password: string): Promise<any>;
}

@injectable()
export class UserService implements IUserService {


	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) {}


	public get(id: string): Promise<User> {
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/${id}`;
		return this.httpClient.get<User>(url);
	}

	public profile(): Promise<User> {
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/profile`;
		return this.httpClient.get<User>(url);
	}

	public getMultiple(ids: string[]): Promise<User[]> {
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/multiple/${ids.join(',')}`;
		return this.httpClient.get<User[]>(url);
	}

	public autocomplete(criteria: string): Promise<User[]> {
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/autocomplete/${criteria}`;
		return this.httpClient.get<User[]>(url);
	}

	public list(pageIndex: number, pageSize: number): Promise<PagedResult<User>> {
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<User>>(url);
	}

	public search(request: UserSearchRequest): Promise<PagedResult<User>> {

		const payload = {
			pageIndex: request.pageIndex,
			pageSize: request.pageSize,
			criteria: request.criteria,
			roleIds: request.roleIds,
			sortBy: request.sortBy,
			sortDirection: request.sortDirection,
		};
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/search`;
		return this.httpClient.post<PagedResult<User>>(url, payload);
	}


	public signup(request: UserCreateRequest): Promise<User> {
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}`;
		return this.httpClient.post<User>(url, request);
	}

	public save(model: User, inviteCode?: string): Promise<User> {

		const payload: { [k: string]: any } = {
			displayName: model.displayName,
			email: model.email,
			description: model.description,
			disabledReasonId: model.disabledReasonId,
			isEnabled: model.isEnabled,
			inviteCode,
		};

		if (model.roles && model.roles.length) { payload.rolesIds = model.roles.map((x) => x.id); }

		let url = `${this.settings.baseUrl}${this.settings.userBaseUrl}`;
		if (model.id !== null && model.id !== undefined && model.id !== '') {
			url += `/${model.id}`;
			return this.httpClient.put(url, payload);
		} else {
			return this.httpClient.post(url, payload);
		}
	}

	public delete(id: string): Promise<any> {
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/${id}`;
		return this.httpClient.delete(url);
	}

	public resetPassword(email: string): Promise<any> {
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/reset-password`;
		const payload = { email };
		return this.httpClient.post(url, payload);
	}

	public changePassword(token: string, password: string): Promise<any> {
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/change-password`;
		const payload = {
			resetToken: token,
			password,
		};
		return this.httpClient.post(url, payload);
	}

}
