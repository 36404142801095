import { container } from 'inversify-props';
import {
	IAccountTypeService, AccountTypeService,
	IBlogService, BlogService,
	ISearchService, SearchService,
	ILogService, LogService,
	IWorkbenchService, WorkbenchService,
} from '../services/website';


export default () => {

	container.bind<IAccountTypeService>('IAccountTypeService').to(AccountTypeService);
	container.bind<IBlogService>('IBlogService').to(BlogService);
	container.bind<ISearchService>('ISearchService').to(SearchService);
	container.bind<ILogService>('ILogService').to(LogService);
	container.bind<IWorkbenchService>('IWorkbenchService').to(WorkbenchService);

};
