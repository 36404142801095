import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { PagedResult, ImportIdentity} from '../../models';
import { IBaseService } from '../BaseService';


export interface IImportIdentityService {

	list(type: string, internalId: string, pageIndex: number, pageSize: number): Promise<PagedResult<ImportIdentity>>;
	save(model: ImportIdentity): Promise<ImportIdentity>;
	delete(externalId: string, type: string): Promise<any>;

}


@injectable()
export class ImportIdentityService implements IImportIdentityService {

	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }


	public list(type: string, internalId: string, pageIndex: number, pageSize: number): Promise<PagedResult<ImportIdentity>> {
		const url = `${this.settings.baseUrl}/api/identity/${type}/${internalId}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<ImportIdentity>>(url);
	}

	public save(model: ImportIdentity): Promise<ImportIdentity> {
		const payload = {
			externalId: model.externalId,
			internalId: model.internalId,
			sourceId: model.sourceId,
			type: model.type,
		};
		const url = `${this.settings.baseUrl}/api/identity/${payload.type}/${payload.internalId}`;
		return this.httpClient.post<ImportIdentity>(url, payload);
	}

	public delete(externalId: string, type: string): Promise<any> {
		const url = `${this.settings.baseUrl}/api/identity/${type}/${externalId}`;
		return this.httpClient.delete(url);
	}
			

}
