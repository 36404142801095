import { SearchRequest } from '../common/SearchRequest';

export class LogSearchRequest extends SearchRequest {

	public startTime: string;
	public endTime: string;
	public logLevels: string[] = [];


	constructor(init?: any) {
		super(init);
		if (!init) { return; }
		if (init.startTime) { this.startTime = init.startTime; }
		if (init.endTime) { this.endTime = init.endTime; }
		if (init.logLevels) {
			this.logLevels = init.logLevels.split(',');
		}
	}

	public collapse(): any {
		const response: { [k: string]: any } = super.collapse();
		if (this.startTime) { response.startTime = this.startTime; }
		if (this.endTime) { response.endTime = this.endTime; }
		if (this.logLevels?.length) { response.logLevels = this.logLevels.join(','); }
		return response;
	}

	public clear() {
		super.clear();
		this.criteria = '';
		this.startTime = '';
		this.endTime = '';
		this.logLevels = [];
	}


}
