import { Role } from './Role';

export class AuthResult {
	public displayName: string;
	public id: string;
	public email: string;
	public kind: string;
	public authToken: string;
	public refreshToken: string;
	public customToken: string;
	public expiresIn: number;
	public isNewUser: boolean;
	public roles: Role[];
}
