import { container } from 'inversify-props';
import {
	ILogger, LoggerService,
	IAnalytics, AnalyticsService,
	IStorage, StorageService,
	IHttpClient, HttpClientService,
	ISettings, Settings,
	INotification, NotificationService,
	IErrorHandler, ErrorHandler,
	IScrollService, ScrollService,
	IExportService, ExportService,
	IGenderService, GenderService,
	ISeasonService, SeasonService,
	IEventBus, EventBus,
} from '../services/common';
import { API } from '../services/api';


export default () => {

	const logger = new LoggerService();
	const httpClient = new HttpClientService(logger);
	const settings = new Settings(httpClient);
	const eventBus = new EventBus();

	container.bind<IEventBus>('IEventBus').toConstantValue(eventBus);
	container.bind<ILogger>('ILogger').toConstantValue(logger);
	container.bind<ISettings>('ISettings').toConstantValue(settings);
	container.bind<IHttpClient>('IHttpClient').toConstantValue(httpClient);
	container.bind<IAnalytics>('IAnalytics').to(AnalyticsService);
	container.bind<IStorage>('IStorage').to(StorageService);
	container.bind<INotification>('INotification').to(NotificationService);
	container.bind<IErrorHandler>('IErrorHandler').to(ErrorHandler);
	container.bind<IScrollService>('IScrollService').to(ScrollService);
	container.bind<IExportService>('IExportService').to(ExportService);
	container.bind<IGenderService>('IGenderService').to(GenderService);
	container.bind<ISeasonService>('ISeasonService').to(SeasonService);
	container.bind<API>('API').to(API);

};
