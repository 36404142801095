import { inject, injectable } from 'inversify-props';
import { IUserService, IInvitationService, IDisabledReasonService, IRoleService, IUserImageService } from './auth';
import { IExportService, IGenderService, ISeasonService } from './common';
import { IConditionService, IFlagService } from './collect';
import { IAuditService } from './history';
import { IArtistAccountService, IStanceService, IRelationService, ICompanyService, ICompanyImageService, ICompanyAccountService, IProService, IProImageService, IProAccountService, IArtistImageService, IArtistService } from './industry';
import { ICatalogService, ICategoryService, IDeckImageService, IDeckNoteService, IDeckFavoriteService, IDeckPriceService, IDeckService, IDeckShapeService, IDeckSizeService, IDeckSurfaceService, IDeckVariationService, ISeriesImageService, ISeriesService, ITechnologyImageService, ITechnologyService, ITruckMountService } from './products';
import { IIntakeService, IImportIdentityService  } from './import';
import { IReportsService } from './reports';
import { IContactReasonService, IContactMessageService } from './messaging';
import { IAccountTypeService, IBlogService, ILogService, IWorkbenchService, ISearchService } from './website';

@injectable()
export class API {

	@inject('IDeckService') public deckService: IDeckService;
	@inject('IIntakeService') public intakeService: IIntakeService;
	@inject('IImportIdentityService') public importIdentityService: IImportIdentityService;
	@inject('IDeckFavoriteService') public deckFavoriteService: IDeckFavoriteService;
	@inject('IFlagService') public flagService: IFlagService;
	@inject('IDisabledReasonService') public disabledReasonService: IDisabledReasonService;
	@inject('IWorkbenchService') public workbenchService: IWorkbenchService;
	@inject('ISearchService') public searchService: ISearchService;
	@inject('ITechnologyService') public technologyService: ITechnologyService;
	@inject('ITechnologyImageService') public technologyImageService: ITechnologyImageService;
	@inject('ITruckMountService') public truckMountService: ITruckMountService;
	@inject('IDeckVariationService') public deckVariationService: IDeckVariationService;
	@inject('IDeckNoteService') public deckNoteService: IDeckNoteService;
	@inject('IDeckImageService') public deckImageService: IDeckImageService;
	@inject('IDeckShapeService') public deckShapeService: IDeckShapeService;
	@inject('IDeckSizeService') public deckSizeService: IDeckSizeService;
	@inject('IDeckSurfaceService') public deckSurfaceService: IDeckSurfaceService;
	@inject('ICategoryService') public categoryService: ICategoryService;
	@inject('ISeriesService') public seriesService: ISeriesService;
	@inject('ISeriesImageService') public seriesImageService: ISeriesImageService;
	@inject('IDeckPriceService') public deckPriceService: IDeckPriceService;
	@inject('ICatalogService') public catalogService: ICatalogService;
	@inject('IRelationService') public relationService: IRelationService;
	@inject('IUserService') public userService: IUserService;
	@inject('IUserImageService') public userImageService: IUserImageService;
	@inject('IRoleService') public roleService: IRoleService;
	@inject('IArtistService') public artistService: IArtistService;
	@inject('IArtistAccountService') public artistAccountService: IArtistAccountService;
	@inject('IArtistImageService') public artistImageService: IArtistImageService;
	@inject('IProService') public proService: IProService;
	@inject('IProAccountService') public proAccountService: IProAccountService;
	@inject('IProImageService') public proImageService: IProImageService;
	@inject('ICompanyService') public companyService: ICompanyService;
	@inject('ICompanyImageService') public companyImageService: ICompanyImageService;
	@inject('ICompanyAccountService') public companyAccountService: ICompanyAccountService;
	@inject('IAccountTypeService') public accountTypeService: IAccountTypeService;
	@inject('IGenderService') public genderService: IGenderService;
	@inject('IConditionService') public conditionService: IConditionService;
	@inject('IStanceService') public stanceService: IStanceService;
	@inject('IReportsService') public reportsService: IReportsService;
	@inject('IAuditService') public auditService: IAuditService;
	@inject('IInvitationService') public invitationService: IInvitationService;
	@inject('IBlogService') public blogService: IBlogService;
	@inject('ILogService') public logService: ILogService;
	@inject('ISeasonService') public seasonService: ISeasonService;
	@inject('IExportService') public exportService: IExportService;
	@inject('IContactMessageService') public contactMessageService: IContactMessageService;
	@inject('IContactReasonService') public contactReasonService: IContactReasonService;
}
