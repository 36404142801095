"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TruckMount = void 0;
var TruckMount = /** @class */ (function () {
    function TruckMount(init) {
        Object.assign(this, init);
    }
    return TruckMount;
}());
exports.TruckMount = TruckMount;
