import 'reflect-metadata';
import { injectable, inject } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { Role } from '../../models';

export interface IRoleService {

	autocomplete(criteria: string): Promise<Role[]>;
	list(): Promise<Role[]>;
	getMultiple(ids: number[]): Promise<Role[]>;

}

@injectable()
export class RoleService implements IRoleService {


	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }


	public autocomplete(criteria: string): Promise<Role[]> {
		const url = `${this.settings.baseUrl}${this.settings.roleBaseUrl}/autocomplete/${criteria}`;
		return this.httpClient.get<Role[]>(url);
	}

	public list(): Promise<Role[]> {
		const url = `${this.settings.baseUrl}${this.settings.roleBaseUrl}`;
		return this.httpClient.get<Role[]>(url);
	}

	public getMultiple(ids: number[]): Promise<Role[]> {
		const url = `${this.settings.baseUrl}${this.settings.roleBaseUrl}/multiple/${ids.join(',')}`;
		return this.httpClient.get<Role[]>(url);
	}

}
