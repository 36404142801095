"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelationService = void 0;
var inversify_props_1 = require("inversify-props");
var RelationService = /** @class */ (function () {
    function RelationService(httpClient, logger, settings) {
        this.httpClient = httpClient;
        this.logger = logger;
        this.settings = settings;
    }
    RelationService.prototype.proToCompanyRelations = function (proId, group) {
        var url = "" + this.settings.baseUrl + this.settings.proBaseUrl + "/" + proId + "/relations/companies?group=" + group;
        return this.httpClient.get(url);
    };
    RelationService.prototype.proToArtistRelations = function (proId, group) {
        var url = "" + this.settings.baseUrl + this.settings.proBaseUrl + "/" + proId + "/relations/artists?group=" + group;
        return this.httpClient.get(url);
    };
    RelationService.prototype.companyToArtistRelations = function (companyId, group) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/" + companyId + "/relations/artists?group=" + group;
        return this.httpClient.get(url);
    };
    RelationService.prototype.companyToProRelations = function (companyId, group) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/" + companyId + "/relations/pros?group=" + group;
        return this.httpClient.get(url);
    };
    RelationService.prototype.artistToProRelations = function (artistId, group) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/" + artistId + "/relations/pros?group=" + group;
        return this.httpClient.get(url);
    };
    RelationService.prototype.artistToCompanyRelations = function (artistId, group) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/" + artistId + "/relations/companies?group=" + group;
        return this.httpClient.get(url);
    };
    RelationService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('IHttpClient')),
        __param(1, inversify_props_1.inject('ILogger')),
        __param(2, inversify_props_1.inject('ISettings'))
    ], RelationService);
    return RelationService;
}());
exports.RelationService = RelationService;
