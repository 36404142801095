
/** Represents the request that is sent to the server when performing a search. */
export class SearchRequest {

	public pageIndex: number;
	public pageSize: number;
	public criteria: string;
	public sortBy: string = null;
	public sortDirection: string = null;

	constructor(init?: any) {
		if (!init) { return; }
		if (init.pageIndex) { this.pageIndex = parseInt(init.pageIndex, null); }
		if (init.pageSize) { this.pageSize = parseInt(init.pageSize, null); }
		if (init.criteria) { this.criteria = init.criteria; } else { this.criteria = null; }
		if (init.sortBy) { this.sortBy = init.sortBy; }
		if (init.sortDirection) { this.sortDirection = init.sortDirection; }
		if (!this.pageIndex) { this.pageIndex = 0; }
		if (!this.pageSize) { this.pageSize = 60; }
	}

	public collapse(): any {
		const response: { [k: string]: any } = {};
		if (this.pageIndex) { response.pageIndex = this.pageIndex; }
		if (this.pageSize) { response.pageSize = this.pageSize; }
		if (this.criteria) { response.criteria = this.criteria; }
		if (this.sortBy) { response.sortBy = this.sortBy; }
		if (this.sortDirection) { response.sortDirection = this.sortDirection; }
		return response;
	}

	public clear() {
		this.pageIndex = 0;
		this.pageSize = 20;
		this.criteria = '';
		this.sortDirection = null;
		this.sortBy = null;
	}

}
