"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArtistSearchRequest = void 0;
var inversify_props_1 = require("inversify-props");
var PersonSearchRequest_1 = require("./PersonSearchRequest");
var ArtistSearchRequest = /** @class */ (function (_super) {
    __extends(ArtistSearchRequest, _super);
    function ArtistSearchRequest(init) {
        var _a, _b, _c;
        var _this = _super.call(this, init) || this;
        _this.proIds = [];
        _this.pros = [];
        _this.companyIds = [];
        _this.companies = [];
        _this.genderIds = [];
        _this.genders = [];
        if (!init) {
            return _this;
        }
        if (init.proIds) {
            _this.proIds = Array.isArray(init.proIds)
                ? init.proIds
                : init.proIds.split(',');
        }
        if (init.companyIds) {
            _this.companyIds = Array.isArray(init.companyIds)
                ? init.companyIds
                : init.companyIds.split(',');
        }
        if (init.genderIds) {
            if (Array.isArray(init.genderIds)) {
                _this.genderIds = init.genderIds;
            }
            else {
                var genders = init.genderIds.split(',');
                _this.genderIds = genders.map(function (x) { return parseInt(x, null); });
            }
        }
        // INSTEAD OF GETTING THESE VALUES FROM THE DATABASE, TRY AND GET THEM FROM LOCAL STORAGE
        var json = _this.storage.retrieve('artist-filter');
        if (!json) {
            return _this;
        }
        var prevFilter = JSON.parse(json);
        if (!prevFilter) {
            return _this;
        }
        if ((_a = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.pros) === null || _a === void 0 ? void 0 : _a.length) {
            var _loop_1 = function (id) {
                var found = prevFilter.pros.find(function (x) { return x.id === id; });
                if (found) {
                    this_1.pros.push(found);
                }
            };
            var this_1 = this;
            for (var _i = 0, _d = _this.proIds; _i < _d.length; _i++) {
                var id = _d[_i];
                _loop_1(id);
            }
        }
        if ((_b = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.companies) === null || _b === void 0 ? void 0 : _b.length) {
            var _loop_2 = function (id) {
                var found = prevFilter.companies.find(function (x) { return x.id === id; });
                if (found) {
                    this_2.companies.push(found);
                }
            };
            var this_2 = this;
            for (var _e = 0, _f = _this.companyIds; _e < _f.length; _e++) {
                var id = _f[_e];
                _loop_2(id);
            }
        }
        if ((_c = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.genders) === null || _c === void 0 ? void 0 : _c.length) {
            var _loop_3 = function (id) {
                var found = prevFilter.genders.find(function (x) { return x.id === id; });
                if (found) {
                    this_3.genders.push(found);
                }
            };
            var this_3 = this;
            for (var _g = 0, _h = _this.genderIds; _g < _h.length; _g++) {
                var id = _h[_g];
                _loop_3(id);
            }
        }
        return _this;
    }
    ArtistSearchRequest.prototype.collapse = function () {
        var _a, _b, _c;
        var response = _super.prototype.collapse.call(this);
        if ((_a = this.proIds) === null || _a === void 0 ? void 0 : _a.length) {
            response.proIds = this.proIds.join(',');
        }
        if ((_b = this.genderIds) === null || _b === void 0 ? void 0 : _b.length) {
            response.genderIds = this.genderIds.join(',');
        }
        if ((_c = this.companyIds) === null || _c === void 0 ? void 0 : _c.length) {
            response.companyIds = this.companyIds.join(',');
        }
        return response;
    };
    ArtistSearchRequest.prototype.clear = function () {
        _super.prototype.clear.call(this);
        this.companyIds = [];
        this.genderIds = [];
        this.proIds = [];
    };
    __decorate([
        inversify_props_1.inject('IStorage')
    ], ArtistSearchRequest.prototype, "storage", void 0);
    return ArtistSearchRequest;
}(PersonSearchRequest_1.PersonSearchRequest));
exports.ArtistSearchRequest = ArtistSearchRequest;
