"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeckNote = void 0;
var DeckNote = /** @class */ (function () {
    function DeckNote(init) {
        Object.assign(this, init);
    }
    return DeckNote;
}());
exports.DeckNote = DeckNote;
