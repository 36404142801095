import { container } from 'inversify-props';
import {
	IImportIdentityService, ImportIdentityService,
	IIntakeService, IntakeService,
} from '../services/import';

export default () => {
	container.bind<IImportIdentityService>('IImportIdentityService').to(ImportIdentityService);
	container.bind<IIntakeService>('IIntakeService').to(IntakeService);
};
