













import { Component } from 'vue-property-decorator';
import { BaseView } from '@shared/views/BaseView';

@Component({})
export default class Breadcrumb extends BaseView {

	public items: any[] = [];

	public mounted() {
		this.eventBus.$on('breadcrumb', (data) => {
			this.items = data;
		});
	}


}

