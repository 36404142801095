"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeckSize = void 0;
var DeckSize = /** @class */ (function () {
    function DeckSize(init) {
        Object.assign(this, init);
    }
    return DeckSize;
}());
exports.DeckSize = DeckSize;
