"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.SeriesService = void 0;
var inversify_props_1 = require("inversify-props");
var SeriesService = /** @class */ (function () {
    function SeriesService(httpClient, logger, settings) {
        this.httpClient = httpClient;
        this.logger = logger;
        this.settings = settings;
    }
    SeriesService.prototype.page = function (pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.seriesBaseUrl + "/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    SeriesService.prototype.get = function (id) {
        var url = "" + this.settings.baseUrl + this.settings.seriesBaseUrl + "/" + id;
        return this.httpClient.get(url);
    };
    SeriesService.prototype.getMultiple = function (ids) {
        var url = "" + this.settings.baseUrl + this.settings.seriesBaseUrl + "/multiple/" + ids.join(',');
        return this.httpClient.get(url);
    };
    SeriesService.prototype.autocomplete = function (criteria) {
        var url = "" + this.settings.baseUrl + this.settings.seriesBaseUrl + "/autocomplete/" + criteria;
        return this.httpClient.get(url);
    };
    SeriesService.prototype.save = function (model) {
        var payload = {
            id: model.id,
            name: model.name,
            description: model.description,
            year: (model.year != null) ? parseInt(model.year.toString(), null) : null,
            isEnabled: model.isEnabled,
        };
        if (model.companies && model.companies.length) {
            payload.companyIds = model.companies.map(function (x) { return x.id; });
        }
        var url = "" + this.settings.baseUrl + this.settings.seriesBaseUrl;
        if (model.id !== null && model.id !== undefined && model.id !== '') {
            url += "/" + model.id;
            return this.httpClient.put(url, payload);
        }
        else {
            return this.httpClient.post(url, payload);
        }
    };
    SeriesService.prototype.listByDeck = function (deckId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/series";
        return this.httpClient.get(url);
    };
    SeriesService.prototype.pageByDeck = function (deckId, pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/series/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    SeriesService.prototype.search = function (request) {
        var url = "" + this.settings.baseUrl + this.settings.seriesBaseUrl + "/search";
        return this.httpClient.post(url, request);
    };
    SeriesService.prototype.delete = function (id) {
        var url = "" + this.settings.baseUrl + this.settings.seriesBaseUrl + "/" + id;
        return this.httpClient.delete(url);
    };
    SeriesService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('IHttpClient')),
        __param(1, inversify_props_1.inject('ILogger')),
        __param(2, inversify_props_1.inject('ISettings'))
    ], SeriesService);
    return SeriesService;
}());
exports.SeriesService = SeriesService;
