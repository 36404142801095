import Vue from 'vue';
import moment from 'moment';

const isoRegex = /^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;


Vue.filter('adminDate', (value: string, outputFormat?: string) => {
	if (value == null || value === '') {
		return '';
	}

	if (!value.match(isoRegex)) {
		// ALL DATES ARE STORED AS UTC. IF THE TIMEZONE INFO ISN'T AVAILABLE,
		// WE APPEND IT SO THE CONVERSIONS WILL BE CORRECT.
		value += '+0000';
	}
	const format: string = (outputFormat) ? outputFormat : 'YYYY-MM-DD hh:mm:ss';
	return moment(String(value)).format(format);
});
