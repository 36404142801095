import { RouteConfig } from 'vue-router';

export default (): RouteConfig[] => {
	return [
		{ path: 'pros/browse', name: 'pro-list', component: () => import('@client/views/industry/pros/ProBrowse.vue') },
		{ path: 'pros/:id', name: 'pro-details', component: () => import('@client/views/industry/pros/ProDetails.vue'), children: [
			{ path: 'decks', name: 'pro-decks', component: () => import('@client/views/industry/pros/ProDecks.vue') },
			{ path: 'companies', name: 'pro-companies', component: () => import('@client/views/industry/pros/ProCompanyTimeline.vue') },
			{ path: 'artists', name: 'pro-artists', component: () => import('@client/views/industry/pros/ProArtists.vue') },
		]},
		
	];
};
