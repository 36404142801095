import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { Technology, PagedResult, TechnologySearchRequest } from '../../models';

export interface ITechnologyService {
	get(id: string): Promise<Technology>;
	getMultiple(ids: string[]): Promise<Technology[]>;
	list(pageIndex: number, pageSize: number): Promise<PagedResult<Technology>>;
	autocomplete(crtieria: string): Promise<Technology[]>;
	search(request: TechnologySearchRequest): Promise<PagedResult<Technology>>;
	save(model: Technology): Promise<Technology>;
	delete(id: string): Promise<any>;
}

@injectable()
export class TechnologyService implements ITechnologyService {


	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) {}


	public get(id: string): Promise<Technology> {
		const url = `${this.settings.baseUrl}${this.settings.technologyBaseUrl}/${id}`;
		return this.httpClient.get<Technology>(url);
	}

	public getMultiple(ids: string[]): Promise<Technology[]> {
		const url = `${this.settings.baseUrl}${this.settings.technologyBaseUrl}/multiple/${ids.join(',')}`;
		return this.httpClient.get<Technology[]>(url);
	}

	public autocomplete(criteria: string): Promise<Technology[]> {
		const url = `${this.settings.baseUrl}${this.settings.technologyBaseUrl}/autocomplete/${criteria}`;
		return this.httpClient.get<Technology[]>(url);
	}

	public list(pageIndex: number, pageSize: number): Promise<PagedResult<Technology>> {
		const url = `${this.settings.baseUrl}${this.settings.technologyBaseUrl}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Technology>>(url);
	}

	public search(request: TechnologySearchRequest): Promise<PagedResult<Technology>> {

		const payload = {
			pageIndex: request.pageIndex,
			pageSize: request.pageSize,
			criteria: request.criteria,
			companyIds: request.companyIds,
			deckIds: request.deckIds,
			deckVariationIds: request.deckVariationIds,
			sortBy: request.sortBy,
			sortDirection: request.sortDirection,
		};
		const url = `${this.settings.baseUrl}${this.settings.technologyBaseUrl}/search`;
		return this.httpClient.post<PagedResult<Technology>>(url, payload);
	}

	public save(model: Technology): Promise<Technology> {

		const payload: { [k: string]: any } = {
			name: model.name,
			marketingCopy: model.marketingCopy,
			description: model.description,
			isEnabled: model.isEnabled,
		};

		if (model.companies && model.companies.length) { payload.companyIds = model.companies.map((x) => x.id); }

		let url = `${this.settings.baseUrl}${this.settings.technologyBaseUrl}`;
		if (model.id !== null && model.id !== undefined && model.id !== '') {
			url += `/${model.id}`;
			return this.httpClient.put(url, payload);
		} else {
			return this.httpClient.post(url, payload);
		}
	}

	public delete(id: string): Promise<any> {
		const url = `${this.settings.baseUrl}${this.settings.technologyBaseUrl}/${id}`;
		return this.httpClient.delete(url);
	}

}
