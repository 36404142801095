import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { ArtistAccount, PagedResult } from '../../models';


export interface IArtistAccountService {

	get(artistId: string, accountId: string): Promise<ArtistAccount>;
	list(artistId: string): Promise<ArtistAccount[]>;
	page(artistId: string, pageIndex: number, pageSize: number): Promise<PagedResult<ArtistAccount>>;
	save(model: ArtistAccount): Promise<ArtistAccount>;
	delete(artistId: string, accountId: string): Promise<any>;

}

@injectable()
export class ArtistAccountService implements IArtistAccountService {


	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) {}


	public get(artistId: string, accountId: string): Promise<ArtistAccount> {
		const url = `${this.settings.baseUrl}${this.settings.artistBaseUrl}/${artistId}/accounts/${accountId}`;
		return this.httpClient.get<ArtistAccount>(url);
	}

	public list(artistId: string): Promise<ArtistAccount[]> {
		const url = `${this.settings.baseUrl}${this.settings.artistBaseUrl}/${artistId}/accounts`;
		return this.httpClient.get<ArtistAccount[]>(url);
	}

	public page(artistId: string, pageIndex: number, pageSize: number): Promise<PagedResult<ArtistAccount>> {
		const url = `${this.settings.baseUrl}${this.settings.artistBaseUrl}/${artistId}/accounts/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<ArtistAccount>>(url);
	}

	public save(model: ArtistAccount): Promise<ArtistAccount> {
		let url = `${this.settings.baseUrl}${this.settings.artistBaseUrl}/${model.artistId}/accounts`;
		if (model.id !== null && model.id !== undefined && model.id !== '') {
			url += `/${model.id}`;
			return this.httpClient.put(url, model);
		}
		return this.httpClient.post(url, model);
	}

	public delete(artistId: string, accountId: string): Promise<any> {
		const url = `${this.settings.baseUrl}${this.settings.artistBaseUrl}/${artistId}/accounts/${accountId}`;
		return this.httpClient.delete(url);
	}

}
