import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { PagedResult, Flag } from '../../models';
import { IBaseService } from '../BaseService';


export interface IFlagService {
	listAll(pageIndex: number, pageSize: number): Promise<PagedResult<Flag>>;
	listByType(targetType: string, pageIndex: number, pageSize: number): Promise<PagedResult<Flag>>;
	listByTarget(targetType: string, targetId: string, pageIndex: number, pageSize: number): Promise<PagedResult<Flag>>;
	save(model: Flag): Promise<Flag>;
	delete(id: number): Promise<any>;
}


@injectable()
export class FlagService implements IFlagService {


	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }


	public listAll(pageIndex: number, pageSize: number): Promise<PagedResult<Flag>> {
		const url = `${this.settings.baseUrl}${this.settings.flagBaseUrl}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Flag>>(url);
	}

	public listByType(targetType: string, pageIndex: number, pageSize: number): Promise<PagedResult<Flag>> {
		const url = `${this.settings.baseUrl}${this.settings.flagBaseUrl}/${targetType}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Flag>>(url);
	}

	public listByTarget(targetType: string, targetId: string, pageIndex: number, pageSize: number): Promise<PagedResult<Flag>> {
		const url = `${this.settings.baseUrl}${this.settings.flagBaseUrl}/${targetType}/${targetId}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Flag>>(url);
	}

	/** Saves the specified flag */
	public save(model: Flag): Promise<Flag> {
		const payload = {
			targetId: model.targetId,
			targetType: model.targetType,
			notes: model.notes,
			priority: model.priority,
			id: model.id,
		};
		let url = `${this.settings.baseUrl}${this.settings.flagBaseUrl}`;
		if (model.id !== null && model.id !== undefined && model.id) {
			url += `/${model.id}`;
			return this.httpClient.put(url, payload);
		}
		return this.httpClient.post(url, payload);
	}

	/** Deletes the specified flag */
	public delete(id: number): Promise<any> {
		const url = `${this.settings.baseUrl}${this.settings.flagBaseUrl}/${id}`;
		return this.httpClient.delete(url);
	}

}
