import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { PagedResult, DisabledReason } from '../../models';
import { IBaseService } from '../BaseService';


export interface IDisabledReasonService extends IBaseService<DisabledReason, number> {
	getMultiple(ids: number[]): Promise<DisabledReason[]>;
}


@injectable()
export class DisabledReasonService implements IDisabledReasonService {

	private cache: Promise<DisabledReason[]>;

	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }

	public get(id: number): Promise<DisabledReason> {
		const url = `${this.settings.baseUrl}${this.settings.disabledReasonBaseUrl}/${id}`;
		return this.httpClient.get<DisabledReason>(url);
	}

	public getMultiple(ids: number[]): Promise<DisabledReason[]> {
		const url = `${this.settings.baseUrl}${this.settings.disabledReasonBaseUrl}/multiple/${ids.join(',')}`;
		return this.httpClient.get<DisabledReason[]>(url);
	}

	public page(pageIndex: number, pageSize: number): Promise<PagedResult<DisabledReason>> {
		const url = `${this.settings.baseUrl}${this.settings.disabledReasonBaseUrl}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<DisabledReason>>(url);
	}

	public list(): Promise<DisabledReason[]> {
		const url = `${this.settings.baseUrl}${this.settings.disabledReasonBaseUrl}`;
		if (!this.cache) { this.cache = this.httpClient.get<DisabledReason[]>(url); }
		return this.cache;
	}

	public save(model: DisabledReason): Promise<DisabledReason> {
		this.cache = null; // INVALIDATE CACHE
		const payload = {
			reason: model.reason,
			id: model.id,
			sortBy: model.sortBy != null ? parseInt(model.sortBy.toString(), null) : 0,
		};
		let url = `${this.settings.baseUrl}${this.settings.disabledReasonBaseUrl}`;
		if (model.id !== null && model.id !== undefined && model.id) {
			url += `/${model.id}`;
			return this.httpClient.put(url, payload);
		}
		return this.httpClient.post(url, payload);
	}

	public delete(id: number): Promise<any> {
		this.cache = null; // INVALIDATE CACHE
		const url = `${this.settings.baseUrl}${this.settings.disabledReasonBaseUrl}/${id}`;
		return this.httpClient.delete(url);
	}

}
