"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.AuditDiff = void 0;
/** Represents a single property that was changed. */
var AuditDiff = /** @class */ (function () {
    function AuditDiff(init) {
        Object.assign(this, init);
    }
    return AuditDiff;
}());
exports.AuditDiff = AuditDiff;
