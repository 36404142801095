import { RouteConfig } from 'vue-router';

export default (): RouteConfig[] => {
	return [
		{ path: 'companies/browse', name: 'company-list', component: () => import('@client/views/industry/companies/CompanyBrowse.vue') },
		{ path: 'companies/:id', name: 'company-details', component: () => import('@client/views/industry/companies/CompanyDetails.vue'), children: [
			{ path: 'decks', name: 'company-decks', component: () => import('@client/views/industry/companies/CompanyDecks.vue') },
			{ path: 'pros', name: 'company-pros', component: () => import('@client/views/industry/companies/CompanyProTimeline.vue') },
			{ path: 'artists', name: 'company-artists', component: () => import('@client/views/industry/companies/CompanyArtists.vue') },
			{ path: 'series', name: 'company-series', component: () => import('@client/views/industry/companies/CompanySeries.vue') },
		] },
	];
};
