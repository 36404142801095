import 'reflect-metadata';
import { injectable, inject } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { Invitation, InvitationStatus, PagedResult, ClaimRequest } from '../../models';

export interface IInvitationService {

	list(): Promise<Invitation[]>;
	page(pageIndex: number, pageSize: number): Promise<PagedResult<Invitation>>;
	create(invitation: Invitation): Promise<Invitation>;
	check(id: string): Promise<InvitationStatus>;
	delete(id: string): Promise<any>;
}

@injectable()
export class InvitationService implements IInvitationService {


	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }


	public list(): Promise<Invitation[]> {
		const url = `${this.settings.baseUrl}${this.settings.invitationBaseUrl}`;
		return this.httpClient.get<Invitation[]>(url);
	}

	public page(pageIndex: number, pageSize: number): Promise<PagedResult<Invitation>> {
		const url = `${this.settings.baseUrl}${this.settings.invitationBaseUrl}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Invitation>>(url);
	}

	public create(invitation: Invitation): Promise<Invitation> {
		const url = `${this.settings.baseUrl}${this.settings.invitationBaseUrl}`;
		const payload: { [k: string]: any } = {
			recipient: invitation.recipient,
		};
		if (invitation.roles && invitation.roles.length) { payload.roleIds = invitation.roles.map((x) => x.id); }
		return this.httpClient.post<Invitation>(url, payload);
	}

	public check(id: string): Promise<InvitationStatus> {
		const url = `${this.settings.baseUrl}${this.settings.invitationBaseUrl}/${id}`;
		return this.httpClient.post<InvitationStatus>(url, null);
	}

	public delete(id: string): Promise<any> {
		const url = `${this.settings.baseUrl}${this.settings.invitationBaseUrl}/${id}`;
		return this.httpClient.delete(url);
	}

}
