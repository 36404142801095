"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonBase = void 0;
var PersonBase = /** @class */ (function () {
    function PersonBase() {
        this.birthYear = null;
        this.birthMonth = null;
        this.birthDay = null;
        this.deathYear = null;
        this.deathMonth = null;
        this.deathDay = null;
    }
    return PersonBase;
}());
exports.PersonBase = PersonBase;
