
/** Represents a single image of a user */
export class UserImage {

	public id: string;
	public isDefault: boolean;
	public userId: string;
	public url: string;
	public data: string;
	public originalWidth: number;
	public originalHeight: number;
	public createdDate: Date;
	public updatedDate: Date;

	constructor(init?: Partial<UserImage>) {
		Object.assign(this, init);
	}

}
