import '@shared/vue.shim';
import 'reflect-metadata';
import { Vue } from 'vue-property-decorator';
import { router } from './routes';
import vuetify from '@shared/vendors/vuetify';
import '@shared/filters';
import '@client/components';
import '@shared/vendors/vee-validate';
import '@client/vendors/font-awesome';
import '@client/vendors/v-viewer';
import '@shared/vendors/multiselect';
import container from '@shared/containers';
import store from '@shared/stores/AppStore';
import './assets/styles/app.scss';
import App from './views/App.vue';
import '@shared/vendors/firebase';
import VueGtag from 'vue-gtag';

const r = router();

Vue.use(VueGtag, {
	config: { id: process.env.VUE_APP_ANALYTICS_TRACKING_CODE },
}, r);

export class AppModule {

	constructor() {
		container();
		this.bootstrap();
	}

	private async bootstrap(): Promise<Vue> {
		const options = {
			el: '#app',
			vuetify : vuetify(),
			router: r,
			store,
			render: (create) => create(App),
		};
		return new Vue(options);
	}
}

const app = new AppModule();


