
export class Tag {
	public id: number;
	public name: string;

	constructor(init?: Partial<Tag>) {
		Object.assign(this, init);
	}

}
