import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { TechnologyImage, PagedResult } from '../../models';


export interface ITechnologyImageService {

	get(techId: string, imageId: string): Promise<TechnologyImage>;
	list(techId: string): Promise<TechnologyImage[]>;
	page(techId: string, pageIndex: number, pageSize: number): Promise<PagedResult<TechnologyImage>>;
	save(image: TechnologyImage): Promise<TechnologyImage>;
	delete(techId: string, imageId: string): Promise<any>;
	revert(techId: string): Promise<any>;
}

@injectable()
export class TechnologyImageService implements ITechnologyImageService {


	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) {}


	public get(techId: string, imageId: string): Promise<TechnologyImage> {
		const url = `${this.settings.baseUrl}${this.settings.technologyBaseUrl}/${techId}/images/${imageId}`;
		return this.httpClient.get<TechnologyImage>(url);
	}

	public list(techId: string): Promise<TechnologyImage[]> {
		const url = `${this.settings.baseUrl}${this.settings.technologyBaseUrl}/${techId}/images`;
		return this.httpClient.get<TechnologyImage[]>(url);
	}

	public page(techId: string, pageIndex: number, pageSize: number): Promise<PagedResult<TechnologyImage>> {
		const url = `${this.settings.baseUrl}${this.settings.technologyBaseUrl}/${techId}/images/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<TechnologyImage>>(url);
	}

	public save(image: TechnologyImage): Promise<TechnologyImage> {
		let url = `${this.settings.baseUrl}${this.settings.technologyBaseUrl}/${image.technologyId}/images`;
		if (image.id !== null && image.id !== undefined && image.id !== '') {
			url += `/${image.id}`;
			return this.httpClient.put(url, image);
		}
		return this.httpClient.post(url, image);
	}

	public delete(techId: string, imageId: string): Promise<any> {
		const url = `${this.settings.baseUrl}${this.settings.technologyBaseUrl}/${techId}/images/${imageId}`;
		return this.httpClient.delete(url);
	}

	public revert(techId: string): Promise<any> {
		const url = `${this.settings.baseUrl}${this.settings.technologyBaseUrl}/${techId}/images/revert`;
		return this.httpClient.post(url, null);
	}


}
