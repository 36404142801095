import { BaseView } from './BaseView';

export class BaseAppView extends BaseView {

	public alertText: string = 'This is some text';
	public showAlert: boolean = false;
	public timeout: number = 4000;
	public alertColor: string = 'orange darken-2';

	constructor() {
		super();
		this.eventBus.$on('notification', this.showNotification);
	}

	/** Displays an on-screen notification to the user */
	public showNotification(text: string, color: string) {
		this.alertText = text;
		this.alertColor = color;
		this.showAlert = true;
	}

}
