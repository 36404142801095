"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyReport = void 0;
var CompanyReport = /** @class */ (function () {
    function CompanyReport() {
        this.dailyAdditions = [];
        this.imageBurndown = [];
        this.recentChanges = [];
        this.recentAdditions = [];
    }
    return CompanyReport;
}());
exports.CompanyReport = CompanyReport;
