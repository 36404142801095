"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.NotificationService = void 0;
require("reflect-metadata");
var inversify_props_1 = require("inversify-props");
var inversify_1 = require("inversify");
/** Displays notification messages to the user. */
var NotificationService = /** @class */ (function () {
    /** Creates a new instance of this object. */
    function NotificationService(logger) {
        this.logger = logger;
    }
    /**
     * Displays a success notification with the specified message
     * @param message The message to display.
     */
    NotificationService.prototype.success = function (message) {
        this.logger.log("[NOTICE] Displaying success notice: " + message);
        this.eventBus.$emit('notification', message, 'green');
        // window.noty.success(message);
    };
    /**
     * Displays a warning notification with the specified message
     * @param message The message to display.
     */
    NotificationService.prototype.warning = function (message) {
        this.logger.log("[NOTICE] Displaying warning notice: " + message);
        this.eventBus.$emit('notification', message, 'orange');
        // window.noty.warning(message);
    };
    /**
     * Displays an error notification with the specified message
     * @param message The message to display.
     */
    NotificationService.prototype.error = function (message) {
        this.logger.log("[NOTICE] Displaying error notice: " + message);
        this.eventBus.$emit('notification', message, 'red');
        // window.noty.error(message);
    };
    /** Displays a notification when a feature is not yet implemented */
    NotificationService.prototype.notImplemented = function () {
        this.logger.log("[NOTICE] Displaying not implemented notice");
        this.eventBus.$emit('notification', "This feature is not yet implemented", 'orange');
    };
    /** Displays a notification when a feature is not yet implemented */
    NotificationService.prototype.validationMessage = function () {
        this.logger.log("[NOTICE] Displaying validation message");
        this.eventBus.$emit('notification', "Please fill out all required fields", 'orange');
    };
    __decorate([
        inversify_1.inject('IEventBus')
    ], NotificationService.prototype, "eventBus", void 0);
    NotificationService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_1.inject('ILogger'))
    ], NotificationService);
    return NotificationService;
}());
exports.NotificationService = NotificationService;
