
import authContainer from './auth.container';
import collectContainer from './collect.container';
import commonContainer from './common.container';
import historyContainer from './history.container';
import industryContainer from './industry.container';
import productsContainer from './products.container';
import reportsContainer from './reports.container';
import websiteContainer from './website.container';
import messagingContainer from './messaging.containter';
import importContainer from './import.container';

export default () => {
	authContainer();
	commonContainer();
	collectContainer();
	historyContainer();
	industryContainer();
	messagingContainer();
	productsContainer();
	reportsContainer();
	websiteContainer();
	importContainer();
};
