import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { PagedResult, BlogEntry, BlogSearchRequest, BlogTag } from '../../models';

export interface IBlogService {

	getEntry(id: string): Promise<BlogEntry>;
	listEntries(pageIndex: number, pageSize: number): Promise<PagedResult<BlogEntry>>;
	listRecentEntries(): Promise<BlogEntry[]>;
	searchEntries(criteria: BlogSearchRequest): Promise<PagedResult<BlogEntry>>;
	saveEntry(entry: BlogEntry): Promise<BlogEntry>;
	deleteEntry(id: string): Promise<void>;
	getMultipleTags(ids: number[]): Promise<BlogTag[]>;
	getTag(id: number): Promise<BlogTag>;
	listTags(): Promise<BlogTag[]>;
	autocompleteTag(criteria: string): Promise<BlogTag[]>;
}


@injectable()
export class BlogService implements IBlogService {

	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) {}

	public getEntry(id: string): Promise<BlogEntry> {
		const url = `${this.settings.baseUrl}${this.settings.blogBaseUrl}/entries/${id}`;
		return this.httpClient.get<BlogEntry>(url);
	}

	public listEntries(pageIndex: number, pageSize: number): Promise<PagedResult<BlogEntry>> {
		const url = `${this.settings.baseUrl}${this.settings.blogBaseUrl}/entries/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<BlogEntry>>(url);
	}

	public listRecentEntries(): Promise<BlogEntry[]> {
		const url = `${this.settings.baseUrl}${this.settings.blogBaseUrl}/entries/recent`;
		return this.httpClient.get<BlogEntry[]>(url);
	}

	public searchEntries(request: BlogSearchRequest): Promise<PagedResult<BlogEntry>> {
		const payload = {
			criteria: request.criteria,
			startTime: (request.startTime === '' || !request.startTime) ? null : request.startTime,
			endTime: (request.endTime === '' || !request.endTime) ? null : request.endTime,
			tagIds: request.tagIds,
			pageIndex: request.pageIndex,
			pageSize: request.pageSize,
			sortBy: request.sortBy,
			sortDirection: request.sortDirection,
		};
		const url = `${this.settings.baseUrl}${this.settings.blogBaseUrl}/entries/search`;
		return this.httpClient.post<PagedResult<BlogEntry>>(url, payload);
	}

	public saveEntry(entry: BlogEntry): Promise<BlogEntry> {
		let url = `${this.settings.baseUrl}${this.settings.blogBaseUrl}/entries`;
		const payload = {
			title: entry.title,
			body: entry.body,
			slug: entry.slug,
			summary: entry.summary,
			publishDate: entry.publishDate,
			isPublished: entry.isPublished,
			tags: [],
		};
		if (entry.tags) {
			for (const tag of entry.tags) {
				payload.tags.push(tag.name);
			}
		}

		if (entry.id) {
			url = `${url}/${entry.id}`;
			return this.httpClient.put<BlogEntry>(url, payload);
		} else {
			return this.httpClient.post<BlogEntry>(url, payload);
		}
	}

	public deleteEntry(id: string): Promise<void> {
		const url = `${this.settings.baseUrl}${this.settings.blogBaseUrl}/entries/${id}`;
		return this.httpClient.delete(url);
	}

	public getTag(id: number): Promise<BlogTag> {
		const url = `${this.settings.baseUrl}${this.settings.blogBaseUrl}/tags/${id}`;
		return this.httpClient.get<BlogTag>(url);
	}

	public listTags(): Promise<BlogTag[]> {
		const url = `${this.settings.baseUrl}${this.settings.blogBaseUrl}/tags`;
		return this.httpClient.get<BlogTag[]>(url);
	}

	public autocompleteTag(criteria: string): Promise<BlogTag[]> {
		const url = `${this.settings.baseUrl}${this.settings.blogBaseUrl}/tags/autocomplete/${criteria}`;
		return this.httpClient.get<BlogTag[]>(url);
	}

	public getMultipleTags(ids: number[]): Promise<BlogTag[]> {
		const url = `${this.settings.baseUrl}${this.settings.blogBaseUrl}/tags/multiple/${ids.join(',')}`;
		return this.httpClient.get<BlogTag[]>(url);
	}


}
