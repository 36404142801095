"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RelationBySubject = void 0;
/** Represents a time-boxed relationship between two objects */
var RelationBySubject = /** @class */ (function () {
    function RelationBySubject() {
        this.timeFrame = [];
    }
    return RelationBySubject;
}());
exports.RelationBySubject = RelationBySubject;
