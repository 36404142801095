"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TechnologySearchRequest = void 0;
var inversify_props_1 = require("inversify-props");
var SearchRequest_1 = require("../common/SearchRequest");
var TechnologySearchRequest = /** @class */ (function (_super) {
    __extends(TechnologySearchRequest, _super);
    function TechnologySearchRequest(init) {
        var _a;
        var _this = _super.call(this, init) || this;
        _this.deckIds = [];
        _this.deckVariationIds = [];
        _this.companyIds = [];
        _this.companies = [];
        if (!init) {
            return _this;
        }
        if (init.companyIds) {
            if (Array.isArray(init.companyIds)) {
                _this.companyIds = init.companyIds;
            }
            else {
                _this.companyIds = init.companyIds.split(',');
            }
        }
        if (init.deckIds) {
            if (Array.isArray(init.deckIds)) {
                _this.deckIds = init.deckIds;
            }
            else {
                _this.deckIds = init.deckIds.split(',');
            }
        }
        if (init.deckVariationIds) {
            if (Array.isArray(init.deckVariationIds)) {
                _this.deckVariationIds = init.deckVariationIds;
            }
            else {
                _this.deckVariationIds = init.deckVariationIds.split(',');
            }
        }
        // INSTEAD OF GETTING THESE VALUES FROM THE DATABASE, TRY AND GET THEM FROM LOCAL STORAGE
        var json = _this.storage.retrieve('technology-filter');
        if (!json) {
            return _this;
        }
        var prevFilter = JSON.parse(json);
        if (!prevFilter) {
            return _this;
        }
        // RESTORE COMPANIES FROM IN MEMORY SEARCH REQUEST
        if ((_a = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.companies) === null || _a === void 0 ? void 0 : _a.length) {
            var _loop_1 = function (id) {
                var found = prevFilter.companies.find(function (x) { return x.id === id; });
                if (found) {
                    this_1.companies.push(found);
                }
            };
            var this_1 = this;
            for (var _i = 0, _b = _this.companyIds; _i < _b.length; _i++) {
                var id = _b[_i];
                _loop_1(id);
            }
        }
        else if (Array.isArray(init.companies)) {
            _this.companies = init.companies;
        }
        return _this;
    }
    /** Collapses the values in this search request object so they can be persisted to a query string */
    TechnologySearchRequest.prototype.collapse = function () {
        var response = _super.prototype.collapse.call(this);
        if (this.companyIds && this.companyIds.length) {
            response.companyIds = this.companyIds.join(',');
        }
        if (this.deckIds && this.deckIds.length) {
            response.deckIds = this.deckIds.join(',');
        }
        if (this.deckVariationIds && this.deckVariationIds.length) {
            response.deckVariationIds = this.deckVariationIds.join(',');
        }
        return response;
    };
    /** Clears the values in this search request object */
    TechnologySearchRequest.prototype.clear = function () {
        _super.prototype.clear.call(this);
        this.deckIds = [];
        this.deckVariationIds = [];
        this.companyIds = [];
    };
    __decorate([
        inversify_props_1.inject('IStorage')
    ], TechnologySearchRequest.prototype, "storage", void 0);
    return TechnologySearchRequest;
}(SearchRequest_1.SearchRequest));
exports.TechnologySearchRequest = TechnologySearchRequest;
