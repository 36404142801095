"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeckMerge = void 0;
var DeckMerge = /** @class */ (function () {
    function DeckMerge() {
        this.masterId = '';
        this.mergeIds = [];
        this.mergePrices = true;
        this.mergeVariations = true;
        this.mergeNotes = true;
        this.mergeArtists = true;
        this.mergePros = true;
        this.mergeSeries = true;
        this.mergeCompanies = true;
        this.mergeCategories = true;
        this.mergeTechnologies = true;
        this.mergeImages = true;
        this.mergeImportIds = true;
    }
    return DeckMerge;
}());
exports.DeckMerge = DeckMerge;
