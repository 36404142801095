import { User } from './User';
import { Role } from './Role';

export class Invitation {
	public id: string;
	public recipient: string;
	public isClaimed: boolean;
	public claimedOn: Date;
	public createdDate: Date;
	public lastUpdatedDate: Date;
	public createdById: string;
	public createdBy: User;
	public lastUpdatedById: string;
	public lastUpdatedBy: User;
	public claimedBy: User;
	public claimedById: string;
	public claimedByIp: string;
	public roles: Role[];
}
