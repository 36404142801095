import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { Intake, PagedResult, IntakeSearchRequest } from '../../models';




export interface IIntakeService {
	list(pageIndex: number, pageSize: number): Promise<PagedResult<Intake>>;
	delete(id: string): Promise<any>;
	assignImportId(externalId: string, internalId: string, source: string, type: string): Promise<any>;
	search(request: IntakeSearchRequest): Promise<PagedResult<Intake>>;
}

@injectable()
export class IntakeService implements IIntakeService {

	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) {}

	public list(pageIndex: number, pageSize: number): Promise<PagedResult<Intake>> {
		const url = `${this.settings.baseUrl}/api/intake/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Intake>>(url);
	}

	public search(request: IntakeSearchRequest): Promise<PagedResult<Intake>> {
		const payload = {
			pageIndex: request.pageIndex,
			pageSize: request.pageSize,
			startYear: request.startYear,
			endYear: request.endYear,
			website: request.website,
			company: request.company,
		};
		const url = `${this.settings.baseUrl}/api/intake/search`;
		return this.httpClient.post<PagedResult<Intake>>(url, payload);
	}

	public delete(id: string): Promise<any> {
		const url = `${this.settings.baseUrl}/api/intake/${id}`;
		return this.httpClient.delete(url);
	}

	public assignImportId(externalId: string, internalId: string, source: string, type: string): Promise<any> {
		const payload = { externalId, internalId, source, type };
		const url = `${this.settings.baseUrl}/api/intake/import-id`;
		return this.httpClient.post<any>(url, payload);
	}

}
