"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportService = void 0;
var inversify_props_1 = require("inversify-props");
var ExportService = /** @class */ (function () {
    function ExportService(httpClient, logger, settings, notification) {
        this.httpClient = httpClient;
        this.logger = logger;
        this.settings = settings;
        this.notification = notification;
    }
    ExportService.prototype.exportToExcel = function (fileName, data) {
        if (data === null || data.length === 0) {
            var message = 'Export to XLS failed because the data provided is null or empty';
            this.logger.error("[EXPORT] " + message);
            this.notification.error(message);
            return;
        }
        var html = '<table border="1px" style="font-size:20px"><tr>';
        var keys = Object.keys(data[0]);
        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
            var key = keys_1[_i];
            html += "<th>" + key + "</th>";
        }
        html += '</tr>';
        for (var _a = 0, data_1 = data; _a < data_1.length; _a++) {
            var row = data_1[_a];
            html += '<tr>';
            for (var _b = 0, keys_2 = keys; _b < keys_2.length; _b++) {
                var col = keys_2[_b];
                html += "<td>" + row[col] + "</td>";
            }
            html += '</tr>';
        }
        html += '</table>';
        var result = "<html xmlns:o=\"urn:schemas-microsoft-com:office:office\" xmlns:x=\"urn:schemas-microsoft-com:office:excel\" xmlns=\"http://www.w3.org/TR/REC-html40\"><head><!--[if gte mso 9]><xml><x:ExcelWorkbook><x:ExcelWorksheets><x:ExcelWorksheet><x:Name>" + fileName + "</x:Name><x:WorksheetOptions><x:DisplayGridlines/></x:WorksheetOptions></x:ExcelWorksheet></x:ExcelWorksheets></x:ExcelWorkbook></xml><![endif]--></head><body><table>" + html + "</table></body></html>";
        var blob = new Blob([result], { type: 'application/vnd.ms-excel;charset=utf-8' });
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName);
        }
        else {
            var link = document.createElement('a');
            if (link.download !== undefined) {
                var url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };
    ExportService.prototype.exportToCsv = function (fileName, data) {
        if (data === null || data.length === 0) {
            var message = 'Export to CSV failed because the data provided is null or empty';
            this.logger.error("[EXPORT] " + message);
            this.notification.error(message);
            return;
        }
        this.logger.log("[EXPORT] Exporting " + data.length + " records to CSV");
        var separator = ',';
        var keys = Object.keys(data[0]);
        var result = keys.join(separator) + '\n' + data.map(function (row) {
            return keys.map(function (key) {
                var cell = row[key] === null || row[key] === undefined ? '' : row[key];
                cell = cell instanceof Date
                    ? cell.toLocaleString()
                    : cell.toString().replace(/"/g, '""');
                if (cell.search(/("|,|\n)/g) >= 0) {
                    cell = "\"" + cell + "\"";
                }
                return cell;
            }).join(separator);
        }).join('\n');
        var blob = new Blob([result], { type: 'text/csv;charset=utf-8' });
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName);
        }
        else {
            var link = document.createElement('a');
            if (link.download !== undefined) {
                var url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };
    ExportService.prototype.exportToJson = function (fileName, data) {
        if (data === null || data.length === 0) {
            var message = 'Export to JSON failed because the data provided is null or empty';
            this.logger.error("[EXPORT] " + message);
            this.notification.error(message);
            return;
        }
        this.logger.log("[EXPORT] Exporting " + data.length + " records to CSV");
        var result = JSON.stringify(data);
        var blob = new Blob([result], { type: 'text/json;charset=utf-8' });
        if (navigator.msSaveBlob) {
            navigator.msSaveBlob(blob, fileName);
        }
        else {
            var link = document.createElement('a');
            if (link.download !== undefined) {
                var url = URL.createObjectURL(blob);
                link.setAttribute('href', url);
                link.setAttribute('download', fileName);
                link.style.visibility = 'hidden';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }
        }
    };
    ExportService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('IHttpClient')),
        __param(1, inversify_props_1.inject('ILogger')),
        __param(2, inversify_props_1.inject('ISettings')),
        __param(3, inversify_props_1.inject('INotification'))
    ], ExportService);
    return ExportService;
}());
exports.ExportService = ExportService;
