import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { PagedResult, ContactMessage, ContactMessageSearchRequest } from '../../models';
import { IBaseService } from '../BaseService';


export interface IContactMessageService extends IBaseService<ContactMessage, string> {
	list(): Promise<ContactMessage[]>;
	page(pageIndex: number, pageSize: number): Promise<PagedResult<ContactMessage>>;
	search(criteria: ContactMessageSearchRequest): Promise<PagedResult<ContactMessage>>;
}


@injectable()
export class ContactMessageService implements IContactMessageService {

	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }

	public get(id: string): Promise<ContactMessage> {
		const url = `${this.settings.baseUrl}${this.settings.contactMessageUrl}/${id}`;
		return this.httpClient.get<ContactMessage>(url);
	}

	public search(criteria: ContactMessageSearchRequest): Promise<PagedResult<ContactMessage>> {
		const url = `${this.settings.baseUrl}${this.settings.contactMessageUrl}/search`;
		return this.httpClient.post<PagedResult<ContactMessage>>(url, criteria);
	}

	public page(pageIndex: number, pageSize: number): Promise<PagedResult<ContactMessage>> {
		const url = `${this.settings.baseUrl}${this.settings.contactMessageUrl}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<ContactMessage>>(url);
	}

	public list(): Promise<ContactMessage[]> {
		const url = `${this.settings.baseUrl}${this.settings.contactMessageUrl}`;
		return this.httpClient.get<ContactMessage[]>(url);
	}

	public save(model: ContactMessage): Promise<ContactMessage> {
		let url = `${this.settings.baseUrl}${this.settings.contactMessageUrl}`;
		if (model.id !== null && model.id !== undefined && model.id) {
			url += `/${model.id}`;
			return this.httpClient.put(url, model);
		}
		return this.httpClient.post(url, model);
	}

	public delete(id: string): Promise<any> {
		const url = `${this.settings.baseUrl}${this.settings.contactMessageUrl}/${id}`;
		return this.httpClient.delete(url);
	}


}
