import { User } from '../auth/User';

export class AccountType {
	public id: number;
	public name: string;
	public sortBy: number;
	public createdDate: Date;
	public updatedDate: Date;
	public createdBy: User;
	public lastUpdatedBy: User;

	constructor(init?: Partial<AccountType>) {
		Object.assign(this, init);
	}

}
