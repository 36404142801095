"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.CatalogSearchRequest = void 0;
var SearchRequest_1 = require("../common/SearchRequest");
var CatalogSearchRequest = /** @class */ (function (_super) {
    __extends(CatalogSearchRequest, _super);
    function CatalogSearchRequest(init) {
        var _this = _super.call(this, init) || this;
        _this.companyIds = [];
        _this.seasonIds = [];
        _this.isEnabled = null;
        _this.isComplete = null;
        if (!init) {
            return _this;
        }
        if (init.companyIds) {
            _this.companyIds = init.companyIds.split(',');
        }
        if (init.seasonIds) {
            _this.seasonIds = init.seasonIds.split(',');
        }
        if (init.startYear) {
            _this.startYear = parseInt(init.startYear, null);
        }
        if (init.endYear) {
            _this.endYear = parseInt(init.endYear, null);
        }
        if (init.isEnabled != null) {
            _this.isEnabled = (init.isEnabled.toString() === 'true');
        }
        if (init.isComplete != null) {
            _this.isComplete = (init.isComplete.toString() === 'true');
        }
        return _this;
    }
    CatalogSearchRequest.prototype.collapse = function () {
        var response = _super.prototype.collapse.call(this);
        if (this.companyIds && this.companyIds.length) {
            response.companyIds = this.companyIds.join(',');
        }
        if (this.seasonIds && this.seasonIds.length) {
            response.seasonIds = this.seasonIds.join(',');
        }
        if (this.startYear) {
            response.startYear = this.startYear;
        }
        if (this.endYear) {
            response.endYear = this.endYear;
        }
        if (this.isEnabled != null) {
            response.isEnabled = this.isEnabled;
        }
        if (this.isComplete != null) {
            response.isComplete = this.isComplete;
        }
        return response;
    };
    CatalogSearchRequest.prototype.clear = function () {
        _super.prototype.clear.call(this);
        this.seasonIds = [];
        this.companyIds = [];
        this.isEnabled = null;
        this.isComplete = null;
        this.criteria = null;
    };
    return CatalogSearchRequest;
}(SearchRequest_1.SearchRequest));
exports.CatalogSearchRequest = CatalogSearchRequest;
