













import { Component } from 'vue-property-decorator';
import { BaseAppView } from '@shared/views/BaseAppView';

declare const window: any;

@Component({})
export default class App extends BaseAppView {

	public alertText: string = 'This is some text';
	public showAlert: boolean = false;
	public timeout: number = 4000;
	public alertColor: string = 'orange darken-2';

	/** Displays an on-screen notification to the user */
	public showNotification(text: string, color: string) {
		this.alertText = text;
		this.alertColor = color;
		this.showAlert = true;
	}

}

