import { container } from 'inversify-props';
import {
	IConditionService, ConditionService,
	IFlagService, FlagService,
} from '../services/collect';


export default () => {
	container.bind<IConditionService>('IConditionService').to(ConditionService);
	container.bind<IFlagService>('IFlagService').to(FlagService);
};
