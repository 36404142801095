"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProReport = void 0;
var ProReport = /** @class */ (function () {
    function ProReport() {
        this.dailyAdditions = [];
        this.imageBurndown = [];
        this.recentChanges = [];
        this.recentAdditions = [];
    }
    return ProReport;
}());
exports.ProReport = ProReport;
