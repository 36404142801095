"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Deck = void 0;
var Deck = /** @class */ (function () {
    function Deck(init) {
        this.selected = false;
        this.visible = true;
        Object.assign(this, init);
    }
    return Deck;
}());
exports.Deck = Deck;
