"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeckSearchRequest = void 0;
var inversify_props_1 = require("inversify-props");
var SearchRequest_1 = require("../common/SearchRequest");
var DeckSearchRequest = /** @class */ (function (_super) {
    __extends(DeckSearchRequest, _super);
    function DeckSearchRequest(init) {
        var _a, _b, _c, _d, _e, _f;
        var _this = _super.call(this, init) || this;
        _this.categoryIds = [];
        _this.technologyIds = [];
        _this.seriesIds = [];
        _this.proIds = [];
        _this.artistIds = [];
        _this.companyIds = [];
        _this.categories = [];
        _this.companies = [];
        _this.pros = [];
        _this.technologies = [];
        _this.artists = [];
        _this.series = [];
        _this.isEnabled = null;
        if (!init) {
            return _this;
        }
        if (init.companyIds) {
            _this.companyIds = Array.isArray(init.companyIds)
                ? init.companyIds
                : init.companyIds.split(',');
        }
        if (init.technologyIds) {
            _this.technologyIds = Array.isArray(init.technologyIds)
                ? init.technologyIds
                : init.technologyIds.split(',');
        }
        if (init.artistIds) {
            _this.artistIds = Array.isArray(init.artistIds)
                ? init.artistIds
                : init.artistIds.split(',');
        }
        if (init.proIds) {
            _this.proIds = Array.isArray(init.proIds)
                ? init.proIds
                : init.proIds.split(',');
        }
        if (init.seriesIds) {
            _this.seriesIds = Array.isArray(init.seriesIds)
                ? init.seriesIds
                : init.seriesIds.split(',');
        }
        if (init.categoryIds) {
            if (Array.isArray(init.categoryIds)) {
                _this.categoryIds = init.categoryIds;
            }
            else {
                var categories = init.categoryIds.split(',');
                _this.categoryIds = categories.map(function (x) { return parseInt(x, null); });
            }
        }
        if (init.startYear) {
            _this.startYear = parseInt(init.startYear, null);
        }
        if (init.endYear) {
            _this.endYear = parseInt(init.endYear, null);
        }
        if (init.isEnabled != null) {
            _this.isEnabled = (init.isEnabled.toString() === 'true');
        }
        // INSTEAD OF GETTING THESE VALUES FROM THE DATABASE, TRY AND GET THEM FROM LOCAL STORAGE
        var json = _this.storage.retrieve('deck-filter');
        if (!json) {
            return _this;
        }
        var prevFilter = JSON.parse(json);
        if (!prevFilter) {
            return _this;
        }
        if ((_a = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.companies) === null || _a === void 0 ? void 0 : _a.length) {
            var _loop_1 = function (id) {
                var found = prevFilter.companies.find(function (x) { return x.id === id; });
                if (found) {
                    this_1.companies.push(found);
                }
            };
            var this_1 = this;
            for (var _i = 0, _g = _this.companyIds; _i < _g.length; _i++) {
                var id = _g[_i];
                _loop_1(id);
            }
        }
        if ((_b = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.artists) === null || _b === void 0 ? void 0 : _b.length) {
            var _loop_2 = function (id) {
                var found = prevFilter.artists.find(function (x) { return x.id === id; });
                if (found) {
                    this_2.artists.push(found);
                }
            };
            var this_2 = this;
            for (var _h = 0, _j = _this.artistIds; _h < _j.length; _h++) {
                var id = _j[_h];
                _loop_2(id);
            }
        }
        if ((_c = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.pros) === null || _c === void 0 ? void 0 : _c.length) {
            var _loop_3 = function (id) {
                var found = prevFilter.pros.find(function (x) { return x.id === id; });
                if (found) {
                    this_3.pros.push(found);
                }
            };
            var this_3 = this;
            for (var _k = 0, _l = _this.proIds; _k < _l.length; _k++) {
                var id = _l[_k];
                _loop_3(id);
            }
        }
        if ((_d = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.technologies) === null || _d === void 0 ? void 0 : _d.length) {
            var _loop_4 = function (id) {
                var found = prevFilter.technologies.find(function (x) { return x.id === id; });
                if (found) {
                    this_4.technologies.push(found);
                }
            };
            var this_4 = this;
            for (var _m = 0, _o = _this.technologyIds; _m < _o.length; _m++) {
                var id = _o[_m];
                _loop_4(id);
            }
        }
        if ((_e = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.series) === null || _e === void 0 ? void 0 : _e.length) {
            var _loop_5 = function (id) {
                var found = prevFilter.series.find(function (x) { return x.id === id; });
                if (found) {
                    this_5.series.push(found);
                }
            };
            var this_5 = this;
            for (var _p = 0, _q = _this.seriesIds; _p < _q.length; _p++) {
                var id = _q[_p];
                _loop_5(id);
            }
        }
        if ((_f = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.categories) === null || _f === void 0 ? void 0 : _f.length) {
            var _loop_6 = function (id) {
                var found = prevFilter.categories.find(function (x) { return x.id === id; });
                if (found) {
                    this_6.categories.push(found);
                }
            };
            var this_6 = this;
            for (var _r = 0, _s = _this.categoryIds; _r < _s.length; _r++) {
                var id = _s[_r];
                _loop_6(id);
            }
        }
        return _this;
    }
    /** Collapses the values in this search request object so they can be persisted to a query string */
    DeckSearchRequest.prototype.collapse = function () {
        var response = _super.prototype.collapse.call(this);
        if (this.companyIds && this.companyIds.length) {
            response.companyIds = this.companyIds.join(',');
        }
        if (this.technologyIds && this.technologyIds.length) {
            response.technologyIds = this.technologyIds.join(',');
        }
        if (this.artistIds && this.artistIds.length) {
            response.artistIds = this.artistIds.join(',');
        }
        if (this.proIds && this.proIds.length) {
            response.proIds = this.proIds.join(',');
        }
        if (this.seriesIds && this.seriesIds.length) {
            response.seriesIds = this.seriesIds.join(',');
        }
        if (this.categoryIds && this.categoryIds.length) {
            response.categoryIds = this.categoryIds.join(',');
        }
        if (this.startYear) {
            response.startYear = this.startYear;
        }
        if (this.endYear) {
            response.endYear = this.endYear;
        }
        if (this.isEnabled != null) {
            response.isEnabled = this.isEnabled;
        }
        return response;
    };
    /** Clears the values in this search request object */
    DeckSearchRequest.prototype.clear = function () {
        _super.prototype.clear.call(this);
        this.artistIds = [];
        this.technologyIds = [];
        this.categoryIds = [];
        this.companyIds = [];
        this.proIds = [];
        this.seriesIds = [];
        this.isEnabled = null;
    };
    __decorate([
        inversify_props_1.inject('IStorage')
    ], DeckSearchRequest.prototype, "storage", void 0);
    return DeckSearchRequest;
}(SearchRequest_1.SearchRequest));
exports.DeckSearchRequest = DeckSearchRequest;
