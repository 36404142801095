"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyImageService = void 0;
var inversify_props_1 = require("inversify-props");
var CompanyImageService = /** @class */ (function () {
    function CompanyImageService(httpClient, logger, settings) {
        this.httpClient = httpClient;
        this.logger = logger;
        this.settings = settings;
    }
    CompanyImageService.prototype.get = function (companyId, imageId) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/" + companyId + "/images/" + imageId;
        return this.httpClient.get(url);
    };
    CompanyImageService.prototype.list = function (companyId) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/" + companyId + "/images";
        return this.httpClient.get(url);
    };
    CompanyImageService.prototype.page = function (companyId, pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/" + companyId + "/images/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    CompanyImageService.prototype.save = function (image) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/" + image.companyId + "/images";
        if (image.id !== null && image.id !== undefined && image.id !== '') {
            url += "/" + image.id;
            return this.httpClient.put(url, image);
        }
        return this.httpClient.post(url, image);
    };
    CompanyImageService.prototype.delete = function (companyId, imageId) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/" + companyId + "/images/" + imageId;
        return this.httpClient.delete(url);
    };
    CompanyImageService.prototype.revert = function (companyId) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/" + companyId + "/images/revert";
        return this.httpClient.post(url, null);
    };
    CompanyImageService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('IHttpClient')),
        __param(1, inversify_props_1.inject('ILogger')),
        __param(2, inversify_props_1.inject('ISettings'))
    ], CompanyImageService);
    return CompanyImageService;
}());
exports.CompanyImageService = CompanyImageService;
