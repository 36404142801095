import { inject } from 'inversify-props';
import { IStorage } from '../../services';
import { SearchRequest } from '../common';
import { Role } from './Role';

/** A request sent to the server when searching users. */
export class UserSearchRequest extends SearchRequest {

	@inject('IStorage')
	public storage: IStorage;

	public roleIds: number[] = [];
	public roles: Role[] = [];


	constructor(init?: any) {

		super(init);
		if (!init) { return; }

		// INSTEAD OF GETTING THESE VALUES FROM THE DATABASE, TRY AND GET THEM FROM LOCAL STORAGE
		const json = this.storage.retrieve('user-filter');
		if (!json) { return; }

		const prevFilter = JSON.parse(json) as UserSearchRequest;
		if (!prevFilter) { return; }

		if (init.roleIds) {
			if (Array.isArray(init.roleIds)) {
				this.roleIds = init.roleIds;
			} else {
				const roles = init.roleIds.split(',');
				this.roleIds = roles.map((x) => parseInt(x, null));
			}
		}

	}

	/** Collapses the values in this search request object so they can be persisted to a query string */
	public collapse(): any {
		const response: { [k: string]: any } = super.collapse();
		if (this.roleIds && this.roleIds.length) { response.roleIds = this.roleIds.join(','); }
		return response;
	}

	/** Clears the values in this search request object */
	public clear() {
		super.clear();
		this.roleIds = [];
	}


}
