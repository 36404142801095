"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeckItemGroup = void 0;
var DeckItemGroup = /** @class */ (function () {
    function DeckItemGroup(init) {
        this.title = '';
        this.list = [];
        Object.assign(this, init);
    }
    return DeckItemGroup;
}());
exports.DeckItemGroup = DeckItemGroup;
