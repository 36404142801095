"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeckNoteService = void 0;
var inversify_props_1 = require("inversify-props");
var DeckNoteService = /** @class */ (function () {
    function DeckNoteService(httpClient, logger, settings) {
        this.httpClient = httpClient;
        this.logger = logger;
        this.settings = settings;
    }
    DeckNoteService.prototype.get = function (deckId, id) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/notes/" + id;
        return this.httpClient.get(url);
    };
    DeckNoteService.prototype.page = function (deckId, pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/notes/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    DeckNoteService.prototype.list = function (deckId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/notes/";
        return this.httpClient.get(url);
    };
    DeckNoteService.prototype.save = function (model) {
        var payload = {
            id: model.id,
            deckId: model.deckId,
            text: model.text,
        };
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + model.deckId + "/notes";
        if (model.id !== null && model.id !== undefined && model.id) {
            url += "/" + model.id;
            return this.httpClient.put(url, payload);
        }
        return this.httpClient.post(url, payload);
    };
    DeckNoteService.prototype.delete = function (deckId, noteId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/notes/" + noteId;
        return this.httpClient.delete(url);
    };
    DeckNoteService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('IHttpClient')),
        __param(1, inversify_props_1.inject('ILogger')),
        __param(2, inversify_props_1.inject('ISettings'))
    ], DeckNoteService);
    return DeckNoteService;
}());
exports.DeckNoteService = DeckNoteService;
