"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TimeFrame = void 0;
/** represents a time boxed value. */
var TimeFrame = /** @class */ (function () {
    function TimeFrame() {
    }
    return TimeFrame;
}());
exports.TimeFrame = TimeFrame;
