
export class UserCreateRequest {
	public invitationCode: string;
	public email: string;
	public displayName: string;
	public password: string;

	constructor(init?: Partial<UserCreateRequest>) {
		Object.assign(this, init);
	}

}
