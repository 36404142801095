"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Stance = void 0;
var Stance = /** @class */ (function () {
    function Stance(init) {
        Object.assign(this, init);
    }
    return Stance;
}());
exports.Stance = Stance;
