"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeckVariationService = void 0;
var inversify_props_1 = require("inversify-props");
var DeckVariationService = /** @class */ (function () {
    function DeckVariationService(httpClient, logger, settings) {
        this.httpClient = httpClient;
        this.logger = logger;
        this.settings = settings;
    }
    DeckVariationService.prototype.get = function (deckId, id) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/variations/" + id;
        return this.httpClient.get(url);
    };
    DeckVariationService.prototype.page = function (deckId, pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/variations/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    DeckVariationService.prototype.list = function (deckId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/variations/";
        return this.httpClient.get(url);
    };
    DeckVariationService.prototype.autocomplete = function (criteria) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/_/variations/autocomplete/" + criteria;
        return this.httpClient.get(url);
    };
    DeckVariationService.prototype.save = function (model) {
        var payload = {
            id: model.id,
            deckId: model.deckId,
            notes: model.notes,
            label: model.label,
            truckMountId: model.truckMountId,
            surfaceId: model.surfaceId,
            shapeId: model.shapeId,
            sizeId: model.sizeId,
            year: model.year ? parseInt(model.year.toString(), null) : null,
            width: model.width ? parseFloat(model.width.toString()) : null,
            length: model.length ? parseFloat(model.length.toString()) : null,
            tailLength: model.tailLength ? parseFloat(model.tailLength.toString()) : null,
            noseLength: model.noseLength ? parseFloat(model.noseLength.toString()) : null,
            numberOfPlys: model.numberOfPlys ? parseInt(model.numberOfPlys.toString(), null) : null,
            wheelBase: model.wheelBase ? parseFloat(model.wheelBase.toString()) : null,
            isReissue: model.isReissue,
            isEnabled: model.isEnabled,
            isApproxYear: model.isApproxYear,
        };
        if (model.technologies && model.technologies.length) {
            payload.technologyIds = model.technologies.map(function (x) { return x.id; });
        }
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + model.deckId + "/variations";
        if (model.id !== null && model.id !== undefined && model.id) {
            url += "/" + model.id;
            return this.httpClient.put(url, payload);
        }
        return this.httpClient.post(url, payload);
    };
    DeckVariationService.prototype.delete = function (deckId, variationId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/variations/" + variationId;
        return this.httpClient.delete(url);
    };
    DeckVariationService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('IHttpClient')),
        __param(1, inversify_props_1.inject('ILogger')),
        __param(2, inversify_props_1.inject('ISettings'))
    ], DeckVariationService);
    return DeckVariationService;
}());
exports.DeckVariationService = DeckVariationService;
