"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ExportType = void 0;
/** Types of data exports that can be done. */
var ExportType;
(function (ExportType) {
    ExportType[ExportType["XLS"] = 0] = "XLS";
    ExportType[ExportType["CSV"] = 1] = "CSV";
    ExportType[ExportType["JSON"] = 2] = "JSON";
})(ExportType = exports.ExportType || (exports.ExportType = {}));
