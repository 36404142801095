












































































import { Component, Watch } from 'vue-property-decorator';
import { BaseView } from '../views/BaseView';
import { SearchCollection } from '../models';

@Component({})
export default class SearchBox extends BaseView {

	public show: boolean = false;
	public criteria: string = '';
	public results: SearchCollection = new SearchCollection();
	public hasResults: boolean = false;
	public initialSearch: boolean = false;

	public $refs!: {
		searchText: any,
	};

	@Watch('show')
	public setFocus() {
		if (this.show) {
			const that = this;
			setTimeout(() => { that.$refs.searchText.focus(); }, 100);

		}
	}

	public async search() {
		if (!this.criteria) { 
			this.initialSearch = false;
			return; 
		}
		this.loading = true;
		this.initialSearch = true;
		try {
			const results = await this.$api.searchService.search(this.criteria);
			if (results.decks.length > 5) { results.decks.splice(5); }
			if (results.companies.length > 5) { results.companies.splice(5); }
			if (results.pros.length > 5) { results.pros.splice(5); }
			if (results.artists.length > 5) { results.artists.splice(5); }
			this.results = results;

			this.hasResults = false;
			if ((this.results.decks && this.results.decks.length) ||
				(this.results.companies && this.results.companies.length) ||
				(this.results.artists && this.results.artists.length) ||
				(this.results.pros && this.results.pros.length)) {
				this.hasResults = true;
			}
		} catch (err) {
			this.errorHandler.handle(err, 'An error occurred while getting search results');
		} finally {
			this.loading = false;
		}
	}

}

