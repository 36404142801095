"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PagerSettings = void 0;
/** Tracks the current status of a paged result set. */
var PagerSettings = /** @class */ (function () {
    function PagerSettings(init) {
        this.previousPageNumber = 0;
        this.nextPageNumber = 0;
        this.pageSize = 0;
        this.pageIndex = 0;
        this.pageNumber = 1;
        this.totalResults = 0;
        this.totalPages = 0;
        this.enableNextButton = false;
        this.enablePrevButton = false;
        Object.assign(this, init);
    }
    return PagerSettings;
}());
exports.PagerSettings = PagerSettings;
