import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { Catalog, PagedResult, CatalogSearchRequest } from '../../models';

export interface ICatalogService {

	get(id: string): Promise<Catalog>;
	getMultiple(ids: string[]): Promise<Catalog[]>;
	search(request: CatalogSearchRequest): Promise<PagedResult<Catalog>>;
	list(pageIndex: number, pageSize: number): Promise<PagedResult<Catalog>>;
	listForCompany(companyId: string, pageIndex: number, pageSize: number): Promise<PagedResult<Catalog>>;
	save(model: Catalog): Promise<Catalog>;
	delete(id: string): Promise<any>;
}

@injectable()
export class CatalogService implements ICatalogService {

	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }

	public get(id: string): Promise<Catalog> {
		const url = `${this.settings.baseUrl}${this.settings.catalogBaseUrl}/${id}`;
		return this.httpClient.get<Catalog>(url);
	}

	public getMultiple(ids: string[]): Promise<Catalog[]> {
		const url = `${this.settings.baseUrl}${this.settings.catalogBaseUrl}/multiple/${ids.join(',')}`;
		return this.httpClient.get<Catalog[]>(url);
	}

	public search(request: CatalogSearchRequest): Promise<PagedResult<Catalog>> {

		const enabled = request.isEnabled == null ? null : (request.isEnabled.toString() === 'true') ? true : false;
		const complete = request.isComplete == null ? null : (request.isComplete.toString() === 'true') ? true : false;

		const payload = {
			pageIndex: request.pageIndex,
			pageSize: request.pageSize,
			criteria: request.criteria,
			seasonIds: request.seasonIds,
			companyIds: request.companyIds,
			isEnabled: enabled,
			isComplete: complete,
			year: {
				start: request.startYear,
				end: request.endYear,
			},
			sortBy: request.sortBy,
			sortDirection: request.sortDirection,
		};
		const url = `${this.settings.baseUrl}${this.settings.catalogBaseUrl}/search`;
		return this.httpClient.post<PagedResult<Catalog>>(url, payload);
	}

	public list(pageIndex: number, pageSize: number): Promise<PagedResult<Catalog>> {
		const url = `${this.settings.baseUrl}${this.settings.catalogBaseUrl}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Catalog>>(url);
	}

	public listForCompany(companyId: string, pageIndex: number, pageSize: number): Promise<PagedResult<Catalog>> {
		const url = `${this.settings.baseUrl}${this.settings.companyBaseUrl}/${companyId}/catalogs/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Catalog>>(url);
	}

	public save(model: Catalog): Promise<Catalog> {
		const payload: { [k: string]: any } = {
			name: model.name,
			companyId: model.companyId,
			seasonId: parseInt(model.seasonId.toString(), null),
			year: parseInt(model.year.toString(), null),
			data: model.data,
			isApproxYear: model.isApproxYear,
			isEnabled: model.isEnabled,
			isComplete: model.isComplete,
			fileType: model.fileType,
		};
		let url = `${this.settings.baseUrl}${this.settings.catalogBaseUrl}`;
		if (model.id !== null && model.id !== undefined && model.id !== '') {
			url += `/${model.id}`;
			return this.httpClient.put(url, payload);
		} else {
			return this.httpClient.post(url, payload);
		}
	}

	public delete(id: string): Promise<any> {
		const url = `${this.settings.baseUrl}${this.settings.catalogBaseUrl}/${id}`;
		return this.httpClient.delete(url);
	}

}
