"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeckSizeService = void 0;
var inversify_props_1 = require("inversify-props");
var DeckSizeService = /** @class */ (function () {
    function DeckSizeService(httpClient, logger, settings) {
        this.httpClient = httpClient;
        this.logger = logger;
        this.settings = settings;
    }
    DeckSizeService.prototype.get = function (id) {
        var url = "" + this.settings.baseUrl + this.settings.deckSizeUrl + "/" + id;
        return this.httpClient.get(url);
    };
    DeckSizeService.prototype.getMultiple = function (ids) {
        var url = "" + this.settings.baseUrl + this.settings.deckSizeUrl + "/multiple/" + ids.join(',');
        return this.httpClient.get(url);
    };
    DeckSizeService.prototype.page = function (pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.deckSizeUrl + "/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    DeckSizeService.prototype.list = function () {
        var url = "" + this.settings.baseUrl + this.settings.deckSizeUrl;
        if (!this.cache) {
            this.cache = this.httpClient.get(url);
        }
        return this.cache;
    };
    DeckSizeService.prototype.save = function (model) {
        this.cache = null; // INVALIDATE CACHE
        var payload = {
            id: model.id,
            name: model.name,
            description: model.description,
            sortBy: (model.sortBy != null) ? parseInt(model.sortBy.toString(), null) : null,
        };
        var url = "" + this.settings.baseUrl + this.settings.deckSizeUrl;
        if (model.id !== null && model.id !== undefined) {
            url += "/" + model.id;
            return this.httpClient.put(url, payload);
        }
        return this.httpClient.post(url, payload);
    };
    DeckSizeService.prototype.delete = function (id) {
        this.cache = null; // INVALIDATE CACHE
        var url = "" + this.settings.baseUrl + this.settings.deckSizeUrl + "/" + id;
        return this.httpClient.delete(url);
    };
    DeckSizeService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('IHttpClient')),
        __param(1, inversify_props_1.inject('ILogger')),
        __param(2, inversify_props_1.inject('ISettings'))
    ], DeckSizeService);
    return DeckSizeService;
}());
exports.DeckSizeService = DeckSizeService;
