"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.PersonSearchRequest = void 0;
var SearchRequest_1 = require("../common/SearchRequest");
var PersonSearchRequest = /** @class */ (function (_super) {
    __extends(PersonSearchRequest, _super);
    function PersonSearchRequest(init) {
        var _this = _super.call(this, init) || this;
        if (!init) {
            return _this;
        }
        if (init.companyIds) {
            _this.companyIds = Array.isArray(init.companyIds)
                ? init.companyIds
                : init.companyIds.split(',');
        }
        if (init.genderIds) {
            if (Array.isArray(init.genderIds)) {
                _this.genderIds = init.genderIds;
            }
            else {
                var genders = init.genderIds.split(',');
                _this.genderIds = genders.map(function (x) { return parseInt(x, null); });
            }
        }
        if (init.isEnabled != null) {
            _this.isEnabled = (init.isEnabled.toString() === 'true');
        }
        return _this;
    }
    PersonSearchRequest.prototype.collapse = function () {
        var response = {};
        if (this.pageIndex) {
            response.pageIndex = this.pageIndex;
        }
        if (this.pageSize) {
            response.pageSize = this.pageSize;
        }
        if (this.criteria) {
            response.criteria = this.criteria;
        }
        if (this.sortBy) {
            response.sortBy = this.sortBy;
        }
        if (this.sortDirection) {
            response.sortDirection = this.sortDirection;
        }
        if (this.companyIds && this.companyIds.length) {
            response.companyIds = this.companyIds.join(',');
        }
        if (this.isEnabled != null) {
            response.isEnabled = this.isEnabled;
        }
        if (this.genderIds && this.genderIds.length) {
            response.genderIds = this.genderIds.join(',');
        }
        return response;
    };
    PersonSearchRequest.prototype.clear = function () {
        this.pageIndex = 0;
        this.pageSize = 20;
        this.criteria = '';
        this.sortDirection = null;
        this.sortBy = null;
        this.isEnabled = null;
        this.companyIds = [];
        this.genderIds = [];
    };
    return PersonSearchRequest;
}(SearchRequest_1.SearchRequest));
exports.PersonSearchRequest = PersonSearchRequest;
