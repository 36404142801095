"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArtistReport = void 0;
var ArtistReport = /** @class */ (function () {
    function ArtistReport() {
        this.dailyAdditions = [];
        this.imageBurndown = [];
        this.recentChanges = [];
        this.recentAdditions = [];
    }
    return ArtistReport;
}());
exports.ArtistReport = ArtistReport;
