"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Subject = void 0;
/**
 * A generic representation of an object in the database. Used when displaying
 * relations between objects without needing to get too specific
 */
var Subject = /** @class */ (function () {
    /** Creates an instance of this object. */
    function Subject(init) {
        Object.assign(this, init);
    }
    return Subject;
}());
exports.Subject = Subject;
