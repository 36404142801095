import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { PagedResult, ContactReason } from '../../models';
import { IBaseService } from '../BaseService';


export interface IContactReasonService extends IBaseService<ContactReason, number> {
	getMultiple(ids: number[]): Promise<ContactReason[]>;
}


@injectable()
export class ContactReasonService implements IContactReasonService {

	private cache: Promise<ContactReason[]>;


	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }

	public get(id: number): Promise<ContactReason> {
		const url = `${this.settings.baseUrl}${this.settings.contactReasonBaseUrl}/${id}`;
		return this.httpClient.get<ContactReason>(url);
	}

	public getMultiple(ids: number[]): Promise<ContactReason[]> {
		const url = `${this.settings.baseUrl}${this.settings.contactReasonBaseUrl}/multiple/${ids.join(',')}`;
		return this.httpClient.get<ContactReason[]>(url);
	}

	public page(pageIndex: number, pageSize: number): Promise<PagedResult<ContactReason>> {
		const url = `${this.settings.baseUrl}${this.settings.contactReasonBaseUrl}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<ContactReason>>(url);
	}

	public list(): Promise<ContactReason[]> {
		const url = `${this.settings.baseUrl}${this.settings.contactReasonBaseUrl}`;
		if (!this.cache) { this.cache = this.httpClient.get<ContactReason[]>(url); }
		return this.cache;
	}

	public save(model: ContactReason): Promise<ContactReason> {
		this.cache = null; // INVALIDATE CACHE
		const payload = {
			id: model.id,
			name: model.name,
			sortBy: (model.sortBy != null) ? parseInt(model.sortBy.toString(), null) : null,
		};

		let url = `${this.settings.baseUrl}${this.settings.contactReasonBaseUrl}`;
		if (model.id !== null && model.id !== undefined) {
			url += `/${model.id}`;
			return this.httpClient.put(url, payload);
		}
		return this.httpClient.post(url, payload);
	}

	public delete(id: number): Promise<any> {
		this.cache = null; // INVALIDATE CACHE
		const url = `${this.settings.baseUrl}${this.settings.contactReasonBaseUrl}/${id}`;
		return this.httpClient.delete(url);
	}

}
