"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Category = void 0;
var Category = /** @class */ (function () {
    function Category(init) {
        this.name = '';
        this.description = '';
        Object.assign(this, init);
    }
    return Category;
}());
exports.Category = Category;
