import { Role } from './Role';
import { DisabledReason } from './DisabledReason';

/** Represents a user of this application */
export class User {

	public id: string;
	public displayName: string;
	public email: string;
	public password: string;
	public roles: Role[];
	public authToken: string;
	public refreshToken: string;
	public lastLogin: string;
	public createdDate: string;
	public updatedDate: string;
	public isEnabled: boolean;
	public disabledReason: DisabledReason;
	public disabledReasonId?: number;
	public emailVerified: boolean;
	public createdById: string;
	public lastUpdatedById: string;
	public createdBy: User;
	public lastUpdatedBy: User;
	public description: string;

	constructor(init?: Partial<User>) {
		Object.assign(this, init);
	}

}
