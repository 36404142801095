import Vue from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

const isoRegex = /^(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

Vue.filter('relativeTime', (value: string, outputFormat?: string) => {
	if (value == null || value === '') {
		return '';
	}

	if (!value.match(isoRegex)) {
		// ALL DATES ARE STORED AS UTC. IF THE TIMEZONE INFO ISN'T AVAILABLE,
		// WE APPEND IT SO THE CONVERSIONS WILL BE CORRECT.
		value += '+0000';
	}

	dayjs.extend(relativeTime);

	return dayjs(value).from(dayjs());

});
