"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeckSurface = void 0;
var DeckSurface = /** @class */ (function () {
    function DeckSurface(init) {
        Object.assign(this, init);
    }
    return DeckSurface;
}());
exports.DeckSurface = DeckSurface;
