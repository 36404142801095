"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Intake = void 0;
var Intake = /** @class */ (function () {
    function Intake(init) {
        this.name = '';
        this.company = '';
        this.website = '';
        Object.assign(this, init);
    }
    return Intake;
}());
exports.Intake = Intake;
