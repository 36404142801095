import { container } from 'inversify-props';
import {
	IDisabledReasonService, DisabledReasonService,
	IRoleService, RoleService,
	IUserImageService, UserImageService,
	IUserService, UserService,
	IInvitationService, InvitationService,
} from '../services/auth';

export default () => {
	container.bind<IDisabledReasonService>('IDisabledReasonService').to(DisabledReasonService);
	container.bind<IRoleService>('IRoleService').to(RoleService);
	container.bind<IInvitationService>('IInvitationService').to(InvitationService);
	container.bind<IUserImageService>('IUserImageService').to(UserImageService);
	container.bind<IUserService>('IUserService').to(UserService);
};
