import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { PagedResult, Deck, Series, Company } from '../../models';


export interface IWorkbenchService {
	companiesMissingDecks(pageIndex: number, pageSize: number): Promise<PagedResult<Company>>;
	companiesMissingImages(pageIndex: number, pageSize: number): Promise<PagedResult<Company>>;

	decksMissingImages(pageIndex: number, pageSize: number): Promise<PagedResult<Deck>>;
	decksMissingSeries(pageIndex: number, pageSize: number): Promise<PagedResult<Deck>>;
	decksIncorrectSeries(pageIndex: number, pageSize: number): Promise<PagedResult<Deck>>;
	deckCategorizer(): Promise<Deck>;
	seriesMissingDecks(pageIndex: number, pageSize: number): Promise<PagedResult<Series>>;
	seriesMissingCompanies(pageIndex: number, pageSize: number): Promise<PagedResult<Series>>;
	seriesMissingImages(pageIndex: number, pageSize: number): Promise<PagedResult<Series>>;
}


@injectable()
export class WorkbenchService implements IWorkbenchService {


	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }


	public decksMissingPros(pageIndex: number, pageSize: number): Promise<PagedResult<Deck>> {
		const url = `${this.settings.baseUrl}/api/workbench/decks/missing/pros/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Deck>>(url);
	}

	public decksMissingImages(pageIndex: number, pageSize: number): Promise<PagedResult<Deck>> {
		const url = `${this.settings.baseUrl}/api/workbench/decks/missing/images/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Deck>>(url);
	}

	public decksMissingSeries(pageIndex: number, pageSize: number): Promise<PagedResult<Deck>> {
		const url = `${this.settings.baseUrl}/api/workbench/decks/missing/series/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Deck>>(url);
	}

	public decksIncorrectSeries(pageIndex: number, pageSize: number): Promise<PagedResult<Deck>> {
		const url = `${this.settings.baseUrl}/api/workbench/decks/incorrect/series/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Deck>>(url);
	}

	public deckCategorizer(): Promise<Deck> {
		const url = `${this.settings.baseUrl}/api/workbench/decks/categorizer`;
		return this.httpClient.get<Deck>(url);
	}

	

	public seriesMissingDecks(pageIndex: number, pageSize: number): Promise<PagedResult<Series>> {
		const url = `${this.settings.baseUrl}/api/workbench/series/missing/decks/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Series>>(url);
	}

	public seriesMissingCompanies(pageIndex: number, pageSize: number): Promise<PagedResult<Series>> {
		const url = `${this.settings.baseUrl}/api/workbench/series/missing/companies/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Series>>(url);
	}

	public seriesMissingImages(pageIndex: number, pageSize: number): Promise<PagedResult<Series>> {
		const url = `${this.settings.baseUrl}/api/workbench/series/missing/images/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Series>>(url);
	}




	public companiesMissingDecks(pageIndex: number, pageSize: number): Promise<PagedResult<Company>> {
		const url = `${this.settings.baseUrl}/api/workbench/companies/missing/decks/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Company>>(url);
	}

	public companiesMissingImages(pageIndex: number, pageSize: number): Promise<PagedResult<Company>> {
		const url = `${this.settings.baseUrl}/api/workbench/companies/missing/images/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Company>>(url);
	}

}
