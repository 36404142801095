"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeckService = void 0;
var inversify_props_1 = require("inversify-props");
var DeckService = /** @class */ (function () {
    function DeckService(httpClient, logger, settings) {
        this.httpClient = httpClient;
        this.logger = logger;
        this.settings = settings;
    }
    DeckService.prototype.get = function (id) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + id;
        return this.httpClient.get(url);
    };
    DeckService.prototype.getMultiple = function (ids) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/multiple/" + ids.join(',');
        return this.httpClient.get(url);
    };
    DeckService.prototype.list = function (pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    DeckService.prototype.search = function (request) {
        var enabled = request.isEnabled == null
            ? null
            : (request.isEnabled.toString() === 'true') ?
                true : false;
        var payload = {
            pageIndex: request.pageIndex,
            pageSize: request.pageSize,
            criteria: request.criteria,
            categoryIds: request.categoryIds,
            seriesIds: request.seriesIds,
            proIds: request.proIds,
            artistIds: request.artistIds,
            companyIds: request.companyIds,
            technologyIds: request.technologyIds,
            isEnabled: enabled,
            year: {
                start: request.startYear,
                end: request.endYear,
            },
            sortBy: request.sortBy,
            sortDirection: request.sortDirection,
        };
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/search";
        return this.httpClient.post(url, payload);
    };
    DeckService.prototype.save = function (deck) {
        var payload = {
            name: deck.name,
            version: deck.version,
            description: deck.description,
            isApproxYear: deck.isApproxYear,
            isEnabled: deck.isEnabled,
            isReissue: deck.isReissue,
            limitedEdition: deck.limitedEdition,
            guestBoard: deck.guestBoard,
            knownFakes: deck.knownFakes,
            setTotal: deck.setTotal ? parseInt(deck.setTotal.toString(), null) : null,
            setNumber: deck.setNumber ? parseInt(deck.setNumber.toString(), null) : null,
            totalRun: deck.totalRun ? parseInt(deck.totalRun.toString(), null) : null,
            year: deck.year ? parseInt(deck.year.toString(), null) : null,
            width: deck.width ? parseFloat(deck.width.toString()) : null,
            length: deck.length ? parseFloat(deck.length.toString()) : null,
            seasonId: deck.seasonId ? deck.seasonId : 0,
        };
        if (deck.companies && deck.companies.length) {
            payload.companyIds = deck.companies.map(function (x) { return x.id; });
        }
        if (deck.artists && deck.artists.length) {
            payload.artistIds = deck.artists.map(function (x) { return x.id; });
        }
        if (deck.pros && deck.pros.length) {
            payload.proIds = deck.pros.map(function (x) { return x.id; });
        }
        if (deck.categories && deck.categories.length) {
            payload.categoryIds = deck.categories.map(function (x) { return x.id; });
        }
        if (deck.series && deck.series.length) {
            payload.seriesIds = deck.series.map(function (x) { return x.id; });
        }
        if (deck.technologies && deck.technologies.length) {
            payload.technologyIds = deck.technologies.map(function (x) { return x.id; });
        }
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl;
        if (deck.id !== null && deck.id !== undefined && deck.id !== '') {
            url += "/" + deck.id;
            return this.httpClient.put(url, payload);
        }
        else {
            return this.httpClient.post(url, payload);
        }
    };
    DeckService.prototype.merge = function (request) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/merge";
        return this.httpClient.post(url, request);
    };
    /** Deletes the specied deck */
    DeckService.prototype.delete = function (id) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + id;
        return this.httpClient.delete(url);
    };
    /** Assigns the specified deck to the specified category */
    DeckService.prototype.assignToCategory = function (deckId, categoryId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/categories/" + categoryId;
        return this.httpClient.post(url, { deckId: deckId, categoryId: categoryId });
    };
    /** Assigns the specified deck to the specified company */
    DeckService.prototype.assignToCompany = function (deckId, companyId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/companies/" + companyId;
        return this.httpClient.post(url, { deckId: deckId, companyId: companyId });
    };
    /** Assigns the specified deck to the specified artist */
    DeckService.prototype.assignToArtist = function (deckId, artistId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/artists/" + artistId;
        return this.httpClient.post(url, { deckId: deckId, artistId: artistId });
    };
    /** Assigns the specified deck to the specified pro */
    DeckService.prototype.assignToPro = function (deckId, proId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/pros/" + proId;
        return this.httpClient.post(url, { deckId: deckId, proId: proId });
    };
    /** Assigns the specified deck to the specified series */
    DeckService.prototype.assignToSeries = function (deckId, seriesId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/series/" + seriesId;
        return this.httpClient.post(url, { deckId: deckId, seriesId: seriesId });
    };
    /** Removes the specified deck from the specified category */
    DeckService.prototype.removeFromCategory = function (deckId, categoryId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/categories/" + categoryId;
        return this.httpClient.delete(url, { deckId: deckId, categoryId: categoryId });
    };
    /** Removes the specified deck from the specified company */
    DeckService.prototype.removeFromCompany = function (deckId, companyId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/companies/" + companyId;
        return this.httpClient.delete(url, { deckId: deckId, companyId: companyId });
    };
    /** Removes the specified deck from the specified artist */
    DeckService.prototype.removeFromArtist = function (deckId, artistId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/artists/" + artistId;
        return this.httpClient.delete(url, { deckId: deckId, artistId: artistId });
    };
    /** Removes the specified deck from the specified pro */
    DeckService.prototype.removeFromPro = function (deckId, proId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/pros/" + proId;
        return this.httpClient.delete(url, { deckId: deckId, proId: proId });
    };
    /** Removes the specified deck from the specified series */
    DeckService.prototype.removeFromSeries = function (deckId, seriesId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/series/" + seriesId;
        return this.httpClient.delete(url, { deckId: deckId, seriesId: seriesId });
    };
    DeckService.prototype.importFromAos = function (aosId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/import/from-aos/" + aosId;
        return this.httpClient.post(url, null);
    };
    DeckService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('IHttpClient')),
        __param(1, inversify_props_1.inject('ILogger')),
        __param(2, inversify_props_1.inject('ISettings'))
    ], DeckService);
    return DeckService;
}());
exports.DeckService = DeckService;
