import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { UserImage, PagedResult } from '../../models';


export interface IUserImageService {

	get(userId: string, imageId: string): Promise<UserImage>;
	list(userId: string): Promise<UserImage[]>;
	page(userId: string, pageIndex: number, pageSize: number): Promise<PagedResult<UserImage>>;
	save(image: UserImage): Promise<UserImage>;
	delete(userId: string, imageId: string): Promise<any>;
	revert(userId: string): Promise<any>;
}

@injectable()
export class UserImageService implements IUserImageService {


	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) {}


	public get(userId: string, imageId: string): Promise<UserImage> {
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/${userId}/images/${imageId}`;
		return this.httpClient.get<UserImage>(url);
	}

	public list(userId: string): Promise<UserImage[]> {
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/${userId}/images`;
		return this.httpClient.get<UserImage[]>(url);
	}

	public page(userId: string, pageIndex: number, pageSize: number): Promise<PagedResult<UserImage>> {
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/${userId}/images/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<UserImage>>(url);
	}

	public save(image: UserImage): Promise<UserImage> {
		let url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/${image.userId}/images`;
		if (image.id !== null && image.id !== undefined && image.id !== '') {
			url += `/${image.id}`;
			return this.httpClient.put(url, image);
		}
		return this.httpClient.post(url, image);
	}

	public delete(userId: string, imageId: string): Promise<any> {
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/${userId}/images/${imageId}`;
		return this.httpClient.delete(url);
	}

	public revert(userId: string): Promise<any> {
		const url = `${this.settings.baseUrl}${this.settings.userBaseUrl}/${userId}/images/revert`;
		return this.httpClient.post(url, null);
	}

}
