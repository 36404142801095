"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ImportIdentity = void 0;
var ImportIdentity = /** @class */ (function () {
    function ImportIdentity(init) {
        Object.assign(this, init);
    }
    return ImportIdentity;
}());
exports.ImportIdentity = ImportIdentity;
