import * as firebase from 'firebase';

firebase.initializeApp({
	apiKey: 'AIzaSyDd7BKFeLHxT__NBkABmaXdx4zQ7KAUO00',
	authDomain: 'api-project-382317753135.firebaseapp.com',
	databaseURL: 'https://api-project-382317753135.firebaseio.com',
	projectId: 'api-project-382317753135',
	storageBucket: 'api-project-382317753135.appspot.com',
	messagingSenderId: '382317753135',
	appId: '1:382317753135:web:e0ea6ce25b5ea80257a7da',
});
