"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.YearRange = void 0;
/** Represents a range of years. */
var YearRange = /** @class */ (function () {
    function YearRange() {
    }
    return YearRange;
}());
exports.YearRange = YearRange;
