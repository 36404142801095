"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PagedResult = void 0;
var PagerSettings_1 = require("./PagerSettings");
/** A wrapper object that holds paged data information. */
var PagedResult = /** @class */ (function () {
    function PagedResult(init) {
        this.pager = new PagerSettings_1.PagerSettings();
        this.results = [];
        Object.assign(this, init);
    }
    return PagedResult;
}());
exports.PagedResult = PagedResult;
