import { IconDefinition, library } from '@fortawesome/fontawesome-svg-core';

const faDeck = {
	prefix: 'fac',
	iconName: 'deck',
	icon: [
		448, 448,
		[],
		null,
		'M229.5,29c26.81,0,49.35,17.99,51.34,40.96l0.16,1.9v297.65l-0.21,2.07c-1.08,10.67-6.67,20.66-15.77,28.14 C255.4,407.64,242.78,412,229.5,412s-25.9-4.36-35.53-12.27c-9.09-7.47-14.69-17.47-15.77-28.14l-0.21-2.07V71.87l0.16-1.9 C180.15,46.99,202.69,29,229.5,29 M229.5,12c-36.03,0-65.55,24.89-68.27,56.5H161v304.79h0.29c3.15,31.23,32.47,55.71,68.21,55.71 s65.06-24.48,68.21-55.71H298V68.5h-0.23C295.05,36.89,265.53,12,229.5,12L229.5,12z M216,112c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S218.76,112,216,112L216,112z M246,112c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S248.76,112,246,112L246,112z M216,152c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S218.76,152,216,152L216,152z M246,152c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S248.76,152,246,152L246,152z M216,312c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S218.76,312,216,312L216,312z M246,312c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S248.76,312,246,312L246,312z M216,352c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S218.76,352,216,352L216,352z M246,352c-2.76,0-5,2.24-5,5s2.24,5,5,5s5-2.24,5-5S248.76,352,246,352L246,352z',
	],
};


library.add(faDeck as IconDefinition);
