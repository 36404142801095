import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { PagedResult, LogEntry, LogSearchRequest } from '../../models';


export interface ILogService {

	get(id: number): Promise<LogEntry>;
	page(pageIndex: number, pageSize: number): Promise<PagedResult<LogEntry>>;
	search(request: LogSearchRequest): Promise<PagedResult<LogEntry>>;

}


@injectable()
export class LogService implements ILogService {

	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }

	public get(id: number): Promise<LogEntry> {
		const url = `${this.settings.baseUrl}${this.settings.logEntryBaseUrl}/${id}`;
		return this.httpClient.get<LogEntry>(url);
	}

	public page(pageIndex: number, pageSize: number): Promise<PagedResult<LogEntry>> {
		const url = `${this.settings.baseUrl}${this.settings.logEntryBaseUrl}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<LogEntry>>(url);
	}


	public search(request: LogSearchRequest): Promise<PagedResult<LogEntry>> {
		const payload = {
			criteria: request.criteria,
			startTime: (request.startTime === '' || !request.startTime) ? null : request.startTime,
			endTime: (request.endTime === '' || !request.endTime) ? null : request.endTime,
			logLevels: request.logLevels,
			pageIndex: request.pageIndex,
			pageSize: request.pageSize,
			sortBy: request.sortBy,
			sortDirection: request.sortDirection,
		};
		const url = `${this.settings.baseUrl}${this.settings.logEntryBaseUrl}/search`;
		return this.httpClient.post<PagedResult<LogEntry>>(url, payload);
	}

}
