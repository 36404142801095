"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Catalog = void 0;
var Catalog = /** @class */ (function () {
    function Catalog(init) {
        Object.assign(this, init);
    }
    return Catalog;
}());
exports.Catalog = Catalog;
