import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { PagedResult, AccountType } from '../../models';
import { IBaseService } from '../BaseService';


export interface IAccountTypeService extends IBaseService<AccountType, number> {
	getMultiple(ids: number[]): Promise<AccountType[]>;
}


@injectable()
export class AccountTypeService implements IAccountTypeService {

	private cache: Promise<AccountType[]>;

	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }

	public get(id: number): Promise<AccountType> {
		const url = `${this.settings.baseUrl}${this.settings.accountTypeBaseUrl}/${id}`;
		return this.httpClient.get<AccountType>(url);
	}

	public getMultiple(ids: number[]): Promise<AccountType[]> {
		const url = `${this.settings.baseUrl}${this.settings.accountTypeBaseUrl}/multiple/${ids.join(',')}`;
		return this.httpClient.get<AccountType[]>(url);
	}

	public page(pageIndex: number, pageSize: number): Promise<PagedResult<AccountType>> {
		const url = `${this.settings.baseUrl}${this.settings.accountTypeBaseUrl}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<AccountType>>(url);
	}

	public list(): Promise<AccountType[]> {
		const url = `${this.settings.baseUrl}${this.settings.accountTypeBaseUrl}`;
		if (!this.cache) { this.cache = this.httpClient.get<AccountType[]>(url); }
		return this.cache;
	}

	public save(model: AccountType): Promise<AccountType> {
		this.cache = null; // INVALIDATE CACHE
		const payload = {
			name: model.name,
			id: model.id,
			sortBy: model.sortBy != null ? parseInt(model.sortBy.toString(), null) : 0,
		};
		let url = `${this.settings.baseUrl}${this.settings.accountTypeBaseUrl}`;
		if (model.id !== null && model.id !== undefined && model.id) {
			url += `/${model.id}`;
			return this.httpClient.put(url, payload);
		}
		return this.httpClient.post(url, payload);
	}

	public delete(id: number): Promise<any> {
		this.cache = null; // INVALIDATE CACHE
		const url = `${this.settings.baseUrl}${this.settings.accountTypeBaseUrl}/${id}`;
		return this.httpClient.delete(url);
	}

}
