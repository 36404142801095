"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProAccountService = void 0;
var inversify_props_1 = require("inversify-props");
var ProAccountService = /** @class */ (function () {
    function ProAccountService(httpClient, logger, settings) {
        this.httpClient = httpClient;
        this.logger = logger;
        this.settings = settings;
    }
    ProAccountService.prototype.get = function (proId, accountId) {
        var url = "" + this.settings.baseUrl + this.settings.proBaseUrl + "/" + proId + "/accounts/" + accountId;
        return this.httpClient.get(url);
    };
    ProAccountService.prototype.list = function (proId) {
        var url = "" + this.settings.baseUrl + this.settings.proBaseUrl + "/" + proId + "/accounts";
        return this.httpClient.get(url);
    };
    ProAccountService.prototype.page = function (proId, pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.proBaseUrl + "/" + proId + "/accounts/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    ProAccountService.prototype.save = function (model) {
        var url = "" + this.settings.baseUrl + this.settings.proBaseUrl + "/" + model.proId + "/accounts";
        if (model.id !== null && model.id !== undefined && model.id !== '') {
            url += "/" + model.id;
            return this.httpClient.put(url, model);
        }
        return this.httpClient.post(url, model);
    };
    ProAccountService.prototype.delete = function (proId, accountId) {
        var url = "" + this.settings.baseUrl + this.settings.proBaseUrl + "/" + proId + "/accounts/" + accountId;
        return this.httpClient.delete(url);
    };
    ProAccountService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('IHttpClient')),
        __param(1, inversify_props_1.inject('ILogger')),
        __param(2, inversify_props_1.inject('ISettings'))
    ], ProAccountService);
    return ProAccountService;
}());
exports.ProAccountService = ProAccountService;
