
export class DisabledReason {
	public id: number;
	public reason: string;
	public sortBy: number;
	public createdDate: Date;
	public updatedDate: Date;

	constructor(init?: Partial<DisabledReason>) {
		Object.assign(this, init);
	}
}
