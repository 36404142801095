"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ArtistService = void 0;
var inversify_props_1 = require("inversify-props");
var ArtistService = /** @class */ (function () {
    function ArtistService(httpClient, logger, settings) {
        this.httpClient = httpClient;
        this.logger = logger;
        this.settings = settings;
    }
    ArtistService.prototype.page = function (pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    ArtistService.prototype.get = function (id) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/" + id;
        return this.httpClient.get(url);
    };
    ArtistService.prototype.getMultiple = function (ids) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/multiple/" + ids.join(',');
        return this.httpClient.get(url);
    };
    ArtistService.prototype.autocomplete = function (criteria) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/autocomplete/" + criteria;
        return this.httpClient.get(url);
    };
    ArtistService.prototype.index = function () {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/index";
        return this.httpClient.get(url);
    };
    ArtistService.prototype.search = function (request) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/search";
        return this.httpClient.post(url, request);
    };
    ArtistService.prototype.save = function (model) {
        var payload = {
            displayName: model.displayName,
            firstName: model.firstName,
            middleName: model.middleName,
            lastName: model.lastName,
            nickName: model.nickName,
            birthDate: model.birthDate,
            aosId: (model.aosId != null) ? parseInt(model.aosId.toString(), null) : null,
            genderId: (model.genderId != null) ? parseInt(model.genderId.toString(), null) : null,
            birthYear: (model.birthYear != null) ? parseInt(model.birthYear.toString(), null) : null,
            birthMonth: (model.birthMonth != null) ? parseInt(model.birthMonth.toString(), null) : null,
            birthDay: (model.birthDay != null) ? parseInt(model.birthDay.toString(), null) : null,
            deathYear: (model.deathYear != null) ? parseInt(model.deathYear.toString(), null) : null,
            deathMonth: (model.deathMonth != null) ? parseInt(model.deathMonth.toString(), null) : null,
            deathDay: (model.deathDay != null) ? parseInt(model.deathDay.toString(), null) : null,
            description: model.description,
            name: model.gender != null ? model.gender.id : 0,
            hometown: model.hometown,
            isEnabled: model.isEnabled,
        };
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl;
        if (model.id !== null && model.id !== undefined && model.id !== '') {
            url += "/" + model.id;
            return this.httpClient.put(url, payload);
        }
        else {
            return this.httpClient.post(url, payload);
        }
    };
    ArtistService.prototype.delete = function (id) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/" + id;
        return this.httpClient.delete(url);
    };
    ArtistService.prototype.listByDeck = function (deckId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/artists";
        return this.httpClient.get(url);
    };
    ArtistService.prototype.pageByDeck = function (deckId, pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/artists/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    ArtistService.prototype.listByCompany = function (companyId) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/" + companyId + "/artists";
        return this.httpClient.get(url);
    };
    ArtistService.prototype.listByCompanyWithCount = function (companyId, pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/" + companyId + "/artists/with-count/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    ArtistService.prototype.pageByCompany = function (companyId, pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/" + companyId + "/artists/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    ArtistService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('IHttpClient')),
        __param(1, inversify_props_1.inject('ILogger')),
        __param(2, inversify_props_1.inject('ISettings'))
    ], ArtistService);
    return ArtistService;
}());
exports.ArtistService = ArtistService;
