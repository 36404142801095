"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.IntakeSearchRequest = void 0;
var SearchRequest_1 = require("../common/SearchRequest");
var IntakeSearchRequest = /** @class */ (function (_super) {
    __extends(IntakeSearchRequest, _super);
    function IntakeSearchRequest(init) {
        var _this = _super.call(this, init) || this;
        _this.company = '';
        _this.website = '';
        if (!init) {
            return _this;
        }
        if (init.startYear) {
            _this.startYear = parseInt(init.startYear, null);
        }
        if (init.endYear) {
            _this.endYear = parseInt(init.endYear, null);
        }
        if (init.website) {
            _this.website = init.website;
        }
        else {
            _this.website = null;
        }
        if (init.company) {
            _this.company = init.company;
        }
        else {
            _this.company = null;
        }
        return _this;
    }
    /** Collapses the values in this search request object so they can be persisted to a query string */
    IntakeSearchRequest.prototype.collapse = function () {
        var response = _super.prototype.collapse.call(this);
        if (this.startYear) {
            response.startYear = this.startYear;
        }
        if (this.endYear) {
            response.endYear = this.endYear;
        }
        if (this.website) {
            response.website = this.website;
        }
        if (this.company) {
            response.company = this.company;
        }
        return response;
    };
    /** Clears the values in this search request object */
    IntakeSearchRequest.prototype.clear = function () {
        _super.prototype.clear.call(this);
        this.website = null;
        this.company = null;
        this.startYear = null;
        this.endYear = null;
    };
    return IntakeSearchRequest;
}(SearchRequest_1.SearchRequest));
exports.IntakeSearchRequest = IntakeSearchRequest;
