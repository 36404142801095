"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProSearchRequest = void 0;
var inversify_props_1 = require("inversify-props");
var PersonSearchRequest_1 = require("./PersonSearchRequest");
var ProSearchRequest = /** @class */ (function (_super) {
    __extends(ProSearchRequest, _super);
    function ProSearchRequest(init) {
        var _a, _b, _c, _d;
        var _this = _super.call(this, init) || this;
        _this.stanceIds = [];
        _this.stances = [];
        _this.artistIds = [];
        _this.artists = [];
        _this.companyIds = [];
        _this.companies = [];
        _this.genderIds = [];
        _this.genders = [];
        if (!init) {
            return _this;
        }
        if (init.artistIds) {
            _this.artistIds = Array.isArray(init.artistIds)
                ? init.artistIds
                : init.artistIds.split(',');
        }
        if (init.companyIds) {
            _this.companyIds = Array.isArray(init.companyIds)
                ? init.companyIds
                : init.companyIds.split(',');
        }
        if (init.genderIds) {
            if (Array.isArray(init.genderIds)) {
                _this.genderIds = init.genderIds;
            }
            else {
                var genders = init.genderIds.split(',');
                _this.genderIds = genders.map(function (x) { return parseInt(x, null); });
            }
        }
        if (init.stanceIds) {
            if (Array.isArray(init.stanceIds)) {
                _this.stanceIds = init.stanceIds;
            }
            else {
                var stances = init.stanceIds.split(',');
                _this.stanceIds = stances.map(function (x) { return parseInt(x, null); });
            }
        }
        // INSTEAD OF GETTING THESE VALUES FROM THE DATABASE, TRY AND GET THEM FROM LOCAL STORAGE
        var json = _this.storage.retrieve('pro-filter');
        if (!json) {
            return _this;
        }
        var prevFilter = JSON.parse(json);
        if (!prevFilter) {
            return _this;
        }
        if ((_a = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.artists) === null || _a === void 0 ? void 0 : _a.length) {
            var _loop_1 = function (id) {
                var found = prevFilter.artists.find(function (x) { return x.id === id; });
                if (found) {
                    this_1.artists.push(found);
                }
            };
            var this_1 = this;
            for (var _i = 0, _e = _this.artistIds; _i < _e.length; _i++) {
                var id = _e[_i];
                _loop_1(id);
            }
        }
        if ((_b = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.stances) === null || _b === void 0 ? void 0 : _b.length) {
            var _loop_2 = function (id) {
                var found = prevFilter.stances.find(function (x) { return x.id === id; });
                if (found) {
                    this_2.stances.push(found);
                }
            };
            var this_2 = this;
            for (var _f = 0, _g = _this.stanceIds; _f < _g.length; _f++) {
                var id = _g[_f];
                _loop_2(id);
            }
        }
        if ((_c = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.companies) === null || _c === void 0 ? void 0 : _c.length) {
            var _loop_3 = function (id) {
                var found = prevFilter.companies.find(function (x) { return x.id === id; });
                if (found) {
                    this_3.companies.push(found);
                }
            };
            var this_3 = this;
            for (var _h = 0, _j = _this.companyIds; _h < _j.length; _h++) {
                var id = _j[_h];
                _loop_3(id);
            }
        }
        if ((_d = prevFilter === null || prevFilter === void 0 ? void 0 : prevFilter.genders) === null || _d === void 0 ? void 0 : _d.length) {
            var _loop_4 = function (id) {
                var found = prevFilter.genders.find(function (x) { return x.id === id; });
                if (found) {
                    this_4.genders.push(found);
                }
            };
            var this_4 = this;
            for (var _k = 0, _l = _this.genderIds; _k < _l.length; _k++) {
                var id = _l[_k];
                _loop_4(id);
            }
        }
        return _this;
    }
    ProSearchRequest.prototype.collapse = function () {
        var _a, _b, _c, _d;
        var response = _super.prototype.collapse.call(this);
        if ((_a = this.artistIds) === null || _a === void 0 ? void 0 : _a.length) {
            response.artistIds = this.artistIds.join(',');
        }
        if ((_b = this.companyIds) === null || _b === void 0 ? void 0 : _b.length) {
            response.companyIds = this.companyIds.join(',');
        }
        if ((_c = this.genderIds) === null || _c === void 0 ? void 0 : _c.length) {
            response.genderIds = this.genderIds.join(',');
        }
        if ((_d = this.stanceIds) === null || _d === void 0 ? void 0 : _d.length) {
            response.stanceIds = this.stanceIds.join(',');
        }
        return response;
    };
    ProSearchRequest.prototype.clear = function () {
        _super.prototype.clear.call(this);
        this.companyIds = [];
        this.genderIds = [];
        this.artistIds = [];
        this.stanceIds = [];
    };
    __decorate([
        inversify_props_1.inject('IStorage')
    ], ProSearchRequest.prototype, "storage", void 0);
    return ProSearchRequest;
}(PersonSearchRequest_1.PersonSearchRequest));
exports.ProSearchRequest = ProSearchRequest;
