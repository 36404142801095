import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { SearchCollection, SearchIndexResult } from '../../models';


export interface ISearchService  {
	search(criteria: string): Promise<SearchCollection>;
	reindex(): Promise<SearchIndexResult>;
}


@injectable()
export class SearchService implements ISearchService {

	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }


	public search(criteria: string): Promise<SearchCollection> {
		const url = `${this.settings.baseUrl}/api/search/${criteria}`;
		return this.httpClient.get<SearchCollection>(url);
	}

	public reindex(): Promise<SearchIndexResult> {
		const url = `${this.settings.baseUrl}/api/search/index`;
		return this.httpClient.get<SearchIndexResult>(url);
	}

}
