"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Settings = void 0;
require("reflect-metadata");
var inversify_props_1 = require("inversify-props");
var Settings = /** @class */ (function () {
    function Settings(httpClient) {
        this.httpClient = httpClient;
        /** Gets the base URL to use for service calls. */
        this.baseUrl = process.env.VUE_APP_SERVICE_URL;
        /** Gets a value indicating if logging should be enabled in this application */
        this.enableLogging = (process.env.VUE_APP_ENABLE_LOGGING === 'true');
        /** Gets the prefix to use when storing things in local storage */
        this.storagePrefix = 'sl';
        /** Gets the amount of time to wait before kicking off an auto complete search. */
        this.autoCompleteTimeout = 500;
        this.autoCompleteListSize = 5;
        this.maxSearchTextSize = 30;
        /** The amount of time in milliseconds that a view should wait before trying to autofocus a form element. */
        this.focusTimeout = 200;
        /** the maxium width in inches that a skateboard can be set to. Decks shouldn't be wider than 30 inches. */
        this.deckMaxWidth = 30;
        /** The maximum length in inches that a skateboard can be set to. Decks shouldn't be longer than 6 feet. */
        this.deckMaxLength = 72;
        /** The minimum number of decks to have in a list before showing the year filter */
        this.minDecksForYearList = 72;
        /** The maximum amount of decks that can be created with the multi add tool. This stops potential issues of creating hundres of decks by accident. */
        this.multiDeckLimit = 30;
        /** The earliest year that can be set when creating decks. Skateboards didn't exist before this. */
        this.earliestYear = 1950;
        /** The year when decks went from old school truck mounts to new school truck mounts */
        this.truckMountDemarcationYear = 1992;
        /** The character to use when separating the series from the pro when naming a deck  */
        this.seriesDelimiter = ' - ';
        /** A collection of colors to use when building charts */
        this.chartColors = [
            '#FFC2CE',
            '#80B3FF',
            '#FD6E8A',
            '#A2122F',
            '#693726',
            '#FFBC1D',
            '#FBD93F',
            '#8A7E66',
            '#182945',
            '#963579',
            '#F168C3',
            '#F168C3',
            '#463F75',
            '#619161',
        ];
        /** The token used to authenticate the current user */
        this.token = 'IVBzr5Ies0UXXLYpgEq7ZPLDwRQ_Lj_nIvmpXGVtVKKomoCa02_dpdfjw5atkafJZZb01Lj7YdHzuQ89-ohk5CG_8K76ZlnEcPacCGouD5rLM5dwWQtfy_3koOI0A0asxKzCkdjk4na-9PBDtYJEwOtdUYRvse3jsEky0ZUuOl1dJklRCJy943ITu9iQTYltblWTRGSQ_H_v1O51OfCpCzspUTP94h8NX-aOtaNCvXWvFkOD6F5rqrkPhn7mIk8nXW9TFIHLZ8CvaWdoHVPbwYr47gRhXqut9CylKljh4PlpXJB8yCUfNN2kFjCzK8zzL1BQPunEU1vr_AGzTA8L-lEMwbV-oDcSA6Ny6Shjnx_tNX33y6T-IcWMgRDFqs5grTRXzMNkP7yFWZOJq8MIfdgZkZNAu2Mu-MYZcNil7e4GP1IX9qg19Ba_4IOkqo4HgEjtZ7C7D7jJnHSchjag-w';
        this.analyticsTrackingCode = process.env.VUE_APP_ANALYTICS_TRACKING_CODE;
        this.auditBaseUrl = '/api/audit';
        this.flagBaseUrl = '/api/flags';
        this.catalogBaseUrl = '/api/catalogs';
        this.accountTypeListUrl = '/api/common/account-types';
        this.disabledReasonBaseUrl = '/api/disabled-reasons';
        this.logEntryBaseUrl = '/api/logs';
        this.conditionBaseUrl = '/api/conditions';
        this.contactReasonBaseUrl = '/api/contact-reasons';
        this.contactMessageUrl = '/api/contact-messages';
        this.seasonBaseUrl = '/api/seasons';
        this.conditionListUrl = '/api/common/conditions';
        this.categoryUrl = '/api/categories';
        this.deckSurfaceUrl = '/api/deck-surfaces';
        this.truckMountUrl = '/api/truck-mounts';
        this.deckShapeUrl = '/api/deck-shapes';
        this.technologyBaseUrl = '/api/technologies';
        this.deckSizeUrl = '/api/deck-sizes';
        this.accountTypeBaseUrl = '/api/account-types';
        this.genderBaseUrl = '/api/genders';
        this.artistBaseUrl = '/api/artists';
        this.seriesBaseUrl = '/api/series';
        this.companyBaseUrl = '/api/companies';
        this.proBaseUrl = '/api/pros';
        this.invitationBaseUrl = '/api/invitations';
        this.blogBaseUrl = '/api/blogs';
        this.deckBaseUrl = '/api/decks';
        this.deckImageBaseUrl = '/api/deck-images';
        this.statsBaseUrl = '/api/stats';
        this.stanceBaseUrl = '/api/stances';
        this.userBaseUrl = '/api/users';
        this.roleBaseUrl = '/api/roles';
        this.confirmModalSettings = {
            color: 'warning',
            icon: 'warning',
            title: 'Please Confirm',
        };
        this.pageIndexParam = 'page';
        this.pageSizeParam = 'pageSize';
        this.criteriaParam = 'criteria';
        this.yearStartParam = 'yearStart';
        this.yearEndParam = 'yearEnd';
        this.categoryIdsParam = 'categoryIds';
        this.seriesIdsParam = 'seriesIds';
        this.proIdsParam = 'proIds';
        this.artistIdsParam = 'artistIds';
        this.companyIdsParam = 'companyIds';
        this.shapeIdsParam = 'shapeIds';
        this.surfaceIdsParam = 'surfaceIds';
        this.sizeIdsParam = 'sizeIds';
        this.sortByParam = 'sort';
        this.sortDirectionParam = 'dir';
        this.requireInviteForSignup = true;
    }
    Settings.prototype.get = function (id) {
        var url = this.baseUrl + "/api/settings/" + id;
        return this.httpClient.get(url);
    };
    Settings.prototype.list = function () {
        var url = this.baseUrl + "/api/settings";
        return this.httpClient.get(url);
    };
    Settings.prototype.save = function (id, value) {
        var url = this.baseUrl + "/api/settings/" + id;
        var payload = { id: id, value: value };
        return this.httpClient.put(url, payload);
    };
    Settings = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('IHttpClient'))
    ], Settings);
    return Settings;
}());
exports.Settings = Settings;
