import { Vue } from 'vue-property-decorator';
import { IEventBus, INotification, ILogger, IErrorHandler, IAnalytics, ISettings, IStorage, IScrollService, EventBus } from '../services';
import { inject } from 'inversify-props';
import { API } from '../services/api';

/** Contains basic properties that all Vue classes in the application can benefit from. */
export class BaseView extends Vue {

	/** Used for validating forms  */
	public $validator: any;

	/** A value indicating whether data is still loading for this view */
	public loading: boolean = false;

	/** An error message that can be displayed when something goes wrong. */
	public errorMessage: string = '';

	/** Application-wide settings. */
	@inject('ISettings')
	public settings: ISettings;

	/** Local storage for saving and retreiving data. */
	@inject('IStorage')
	public storage: IStorage;

	/** Event bus that's used to fire and handle global events across the application. */
	@inject('IEventBus')
	protected eventBus: IEventBus;

	/** Logger that logs to the console window. */
	@inject('ILogger')
	protected logger: ILogger;

	/** Used for displaying notifications to the user. */
	@inject('INotification')
	protected notification: INotification;

	/** Responsible for handling any errors that may occur. */
	@inject('IErrorHandler')
	protected errorHandler: IErrorHandler;

	/** Saves analytics/usage data. */
	@inject('IAnalytics')
	protected analytics: IAnalytics;

	/** Responsible for scrolling the page */
	@inject('IScrollService')
	protected scroll: IScrollService;

	/** Acts as a wrapper around every service class in this application. */
	@inject('API')
	protected $api: API;

	/** Sets the title of the webpage */
	public setTitle(title: string) {
		document.title = title;
	}

	/** Sets the breadcrumb for the current page. */
	public setBreadcrumb(breadcrumb: any) {
		this.eventBus.$emit('breadcrumb', breadcrumb);
	}

}
