"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ErrorHandler = void 0;
require("reflect-metadata");
var inversify_props_1 = require("inversify-props");
var inversify_1 = require("inversify");
var ErrorHandler = /** @class */ (function () {
    function ErrorHandler(logger, notification) {
        this.logger = logger;
        this.notification = notification;
    }
    /** Handles the specified error message */
    ErrorHandler.prototype.handle = function (error, defaultMessage) {
        var message = defaultMessage;
        if (error != null) {
            if (error.response != null &&
                error.response.data != null &&
                error.response.data.message != null) {
                message = error.response.data.message;
            }
            else if (error.message === 'Network Error') {
                message = 'Connection to the server could not be established';
            }
            this.notification.error(message);
            throw error;
        }
        this.notification.error(message);
        this.logger.error("[ERROR] " + message);
    };
    ErrorHandler = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_1.inject('ILogger')),
        __param(1, inversify_1.inject('INotification'))
    ], ErrorHandler);
    return ErrorHandler;
}());
exports.ErrorHandler = ErrorHandler;
