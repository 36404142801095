"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ScrollService = void 0;
var inversify_props_1 = require("inversify-props");
var ScrollService = /** @class */ (function () {
    function ScrollService() {
    }
    ScrollService.prototype.scrollToTop = function () {
        window.scroll({ top: 0, left: 0, behavior: 'smooth' });
    };
    ScrollService.prototype.scrollToId = function (id) {
        document.querySelector('.hello').scrollIntoView({
            behavior: 'smooth',
        });
    };
    ScrollService = __decorate([
        inversify_props_1.injectable()
    ], ScrollService);
    return ScrollService;
}());
exports.ScrollService = ScrollService;
