"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.AnalyticsService = void 0;
require("reflect-metadata");
var inversify_props_1 = require("inversify-props");
/** Handles sending usage analytics to the analytics service */
var AnalyticsService = /** @class */ (function () {
    function AnalyticsService(settings, storage, logger) {
        this.settings = settings;
        this.storage = storage;
        this.logger = logger;
    }
    /**
     * Sends an analytics event to the analytics service
     * @param category The category this event falls in.
     * @param action The action that was performed
     * @param label A label to classify it better
     * @param value A value of the event if available.
     */
    AnalyticsService.prototype.event = function (event, category, action, label, value) {
        var payload = {
            event: event,
            category: category,
            action: action,
            label: label,
            value: value,
            noninteraction: false,
        };
        window.$gtm.trackEvent(payload);
    };
    /**
     * Emits a page view to the analytics service.
     * @param page The page being viewed.
     */
    AnalyticsService.prototype.pageView = function (page) {
        // TODO: Fill this out
    };
    /**
     * Emits a timing to the analytics service to track how long things are taking.
     * @param category The category of this timing
     * @param timingVar The name of the timing
     * @param value The amount of time in milliseconds it took for this timing.
     */
    AnalyticsService.prototype.timing = function (category, timingVar, value) {
        // TODO: Fill this out
    };
    /**
     * Starts a timer with the specified name.
     * @param name The name of the timer to start.
     */
    AnalyticsService.prototype.startTimer = function (name) {
        var key = btoa(name);
        var start = new Date().toString();
        this.storage.store(key, start);
        this.logger.log("[ANALYTICS] Starting timer at: " + start);
    };
    /**
     * Stops the specified timer and logs it as a timing event in the analytics service.
     * @param name The name of the timer to stop.
     * @param category A category to further classify this timing.
     */
    AnalyticsService.prototype.stopTimer = function (name, category) {
        var key = btoa(name);
        var start = new Date(this.storage.retrieve(key));
        var end = new Date();
        var elapsed = (end.getTime() - start.getTime());
        this.logger.log("[ANALYTICS] Stopping timer at: " + end.toString() + ".  Elapsed time: " + elapsed + "ms");
        this.timing(category, name, elapsed);
    };
    AnalyticsService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('ISettings')),
        __param(1, inversify_props_1.inject('IStorage')),
        __param(2, inversify_props_1.inject('ILogger'))
    ], AnalyticsService);
    return AnalyticsService;
}());
exports.AnalyticsService = AnalyticsService;
