"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.StorageService = void 0;
require("reflect-metadata");
var inversify_props_1 = require("inversify-props");
/** Responsible for storing data to local storage in the browser. */
var StorageService = /** @class */ (function () {
    /**
     * Creates an instance of the StorageService class
     */
    function StorageService(settings, logger) {
        this.settings = settings;
        this.logger = logger;
    }
    /**
     * Stores a value to local storage using the the specified key.
     * @param key The name of the key to add to local storage.
     * @param value The object to add to local storage.
     */
    StorageService.prototype.store = function (key, value) {
        key = this.settings.storagePrefix + "_" + key;
        value = JSON.stringify(value);
        localStorage.setItem(key, value);
        this.logger.log("[STORAGE] Stored object with the key " + key + " to local storage");
    };
    /**
     * Retrieves a value from local storage with the specified key.
     * @param key The key to retrieve from local storage.
     */
    StorageService.prototype.retrieve = function (key) {
        key = this.settings.storagePrefix + "_" + key;
        var json = localStorage.getItem(key);
        this.logger.log("[STORAGE] Retrieved object with the key " + key + " from local storage");
        return json;
    };
    /**
     * Removes the specified key from local storage.
     * @param key The key to remove from local storage.
     */
    StorageService.prototype.remove = function (key) {
        key = this.settings.storagePrefix + "_" + key;
        var json = localStorage.removeItem(key);
        this.logger.log("[STORAGE] Removed object with the key " + key + " from local storage");
    };
    StorageService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('ISettings')),
        __param(1, inversify_props_1.inject('ILogger'))
    ], StorageService);
    return StorageService;
}());
exports.StorageService = StorageService;
