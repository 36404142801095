"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Flag = void 0;
/** Represents a marker thats put on an object that needs to be flagged for attention */
var Flag = /** @class */ (function () {
    function Flag(init) {
        Object.assign(this, init);
    }
    return Flag;
}());
exports.Flag = Flag;
