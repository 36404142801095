import { RouteConfig } from 'vue-router';

export default (): RouteConfig[] => {
	return [
		{ path: 'artists/browse', name: 'artist-list', component: () => import('@client/views/industry/artists/ArtistBrowse.vue') },
		{ path: 'artists/:id', name: 'artist-details', component: () => import('@client/views/industry/artists/ArtistDetails.vue'), children: [
			{ path: 'decks', name: 'artist-decks', component: () => import('@client/views/industry/artists/ArtistDecks.vue') },
			{ path: 'companies', name: 'artist-companies', component: () => import('@client/views/industry/artists/ArtistCompanies.vue') },
			{ path: 'pros', name: 'artist-pros', component: () => import('@client/views/industry/artists/ArtistPros.vue') },
		]},
	];
};
