"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeckImageService = void 0;
var inversify_props_1 = require("inversify-props");
var DeckImageService = /** @class */ (function () {
    function DeckImageService(httpClient, logger, settings) {
        this.httpClient = httpClient;
        this.logger = logger;
        this.settings = settings;
    }
    /** Gets details about a specified deck image. */
    DeckImageService.prototype.get = function (deckId, imageId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/images/" + imageId;
        return this.httpClient.get(url);
    };
    /** Returns the entire list of deck images for the specified deck */
    DeckImageService.prototype.list = function (deckId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/images";
        return this.httpClient.get(url);
    };
    /** Returns a single page of deck images */
    DeckImageService.prototype.page = function (deckId, pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/images/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    /** Saves the specified image */
    DeckImageService.prototype.save = function (deckImage) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckImage.deckId + "/images";
        if (deckImage.id !== null && deckImage.id !== undefined && deckImage.id !== '') {
            url += "/" + deckImage.id;
            return this.httpClient.put(url, deckImage);
        }
        return this.httpClient.post(url, deckImage);
    };
    /** Deletes the specified image */
    DeckImageService.prototype.delete = function (deckId, imageId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/images/" + imageId;
        return this.httpClient.delete(url);
    };
    /** Reverts the default image back to a stub image. */
    DeckImageService.prototype.revert = function (deckId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/images/revert";
        return this.httpClient.post(url, null);
    };
    /** Trims the white space off a default image */
    DeckImageService.prototype.trimDefault = function (deckId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/images/trim-default";
        return this.httpClient.post(url, null);
    };
    DeckImageService.prototype.generatePalette = function (deckId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/images/color-palette";
        return this.httpClient.post(url, null);
    };
    DeckImageService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('IHttpClient')),
        __param(1, inversify_props_1.inject('ILogger')),
        __param(2, inversify_props_1.inject('ISettings'))
    ], DeckImageService);
    return DeckImageService;
}());
exports.DeckImageService = DeckImageService;
