"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CategorySearchRequest = void 0;
var inversify_props_1 = require("inversify-props");
var SearchRequest_1 = require("../common/SearchRequest");
var CategorySearchRequest = /** @class */ (function (_super) {
    __extends(CategorySearchRequest, _super);
    function CategorySearchRequest(init) {
        var _this = _super.call(this, init) || this;
        _this.isEnabled = null;
        _this.isFeatured = null;
        if (!init) {
            return _this;
        }
        if (init.isEnabled != null) {
            _this.isEnabled = (init.isEnabled.toString() === 'true');
        }
        if (init.isFeatured != null) {
            _this.isFeatured = (init.isFeatured.toString() === 'true');
        }
        // INSTEAD OF GETTING THESE VALUES FROM THE DATABASE, TRY AND GET THEM FROM LOCAL STORAGE
        var json = _this.storage.retrieve('category-filter');
        if (!json) {
            return _this;
        }
        var prevFilter = JSON.parse(json);
        if (!prevFilter) {
            return _this;
        }
        return _this;
    }
    /** Collapses the values in this search request object so they can be persisted to a query string */
    CategorySearchRequest.prototype.collapse = function () {
        var response = _super.prototype.collapse.call(this);
        if (this.isEnabled != null) {
            response.isEnabled = this.isEnabled;
        }
        if (this.isFeatured != null) {
            response.isFeatured = this.isEnabled;
        }
        return response;
    };
    /** Clears the values in this search request object */
    CategorySearchRequest.prototype.clear = function () {
        _super.prototype.clear.call(this);
        this.isEnabled = null;
        this.isFeatured = null;
    };
    __decorate([
        inversify_props_1.inject('IStorage')
    ], CategorySearchRequest.prototype, "storage", void 0);
    return CategorySearchRequest;
}(SearchRequest_1.SearchRequest));
exports.CategorySearchRequest = CategorySearchRequest;
