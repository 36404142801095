"use strict";
var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
var __param = (this && this.__param) || function (paramIndex, decorator) {
    return function (target, key) { decorator(target, key, paramIndex); }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.CompanyService = void 0;
var inversify_props_1 = require("inversify-props");
/** Responsible for all service call operations to the Company end-points */
var CompanyService = /** @class */ (function () {
    function CompanyService(httpClient, logger, settings) {
        this.httpClient = httpClient;
        this.logger = logger;
        this.settings = settings;
    }
    CompanyService.prototype.page = function (pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    CompanyService.prototype.get = function (id) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/" + id;
        return this.httpClient.get(url);
    };
    CompanyService.prototype.getMultiple = function (ids) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/multiple/" + ids.join(',');
        return this.httpClient.get(url);
    };
    CompanyService.prototype.autocomplete = function (criteria) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/autocomplete/" + criteria;
        return this.httpClient.get(url);
    };
    CompanyService.prototype.index = function () {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/index";
        return this.httpClient.get(url);
    };
    CompanyService.prototype.search = function (request) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/search";
        return this.httpClient.post(url, request);
    };
    CompanyService.prototype.save = function (company) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl;
        var enabled = company.isEnabled == null ? null : (company.isEnabled.toString() === 'true') ? true : false;
        var popular = company.isPopular == null ? false : (company.isPopular.toString() === 'true') ? true : false;
        var payload = {
            name: company.name,
            headquarters: company.headquarters,
            aliases: company.aliases,
            startYear: (company.startYear != null) ? parseInt(company.startYear.toString(), null) : null,
            endYear: (company.endYear != null) ? parseInt(company.endYear.toString(), null) : null,
            aosId: (company.aosId != null) ? parseInt(company.aosId.toString(), null) : null,
            description: company.description,
            isEnabled: enabled,
            isPopular: popular,
            outOfBusiness: (company.endYear != null),
        };
        if (company.id) {
            url = url + "/" + company.id;
            return this.httpClient.put(url, payload);
        }
        else {
            return this.httpClient.post(url, payload);
        }
    };
    CompanyService.prototype.delete = function (id) {
        var url = "" + this.settings.baseUrl + this.settings.companyBaseUrl + "/" + id;
        return this.httpClient.delete(url);
    };
    CompanyService.prototype.listByDeck = function (deckId) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/companies";
        return this.httpClient.get(url);
    };
    CompanyService.prototype.pageByDeck = function (deckId, pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.deckBaseUrl + "/" + deckId + "/companies/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    CompanyService.prototype.listByProWithCount = function (proId, pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.proBaseUrl + "/" + proId + "/companies/with-count/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    CompanyService.prototype.listByArtistWithCount = function (artistId, pageIndex, pageSize) {
        var url = "" + this.settings.baseUrl + this.settings.artistBaseUrl + "/" + artistId + "/companies/with-count/" + pageIndex + "/" + pageSize;
        return this.httpClient.get(url);
    };
    CompanyService = __decorate([
        inversify_props_1.injectable(),
        __param(0, inversify_props_1.inject('IHttpClient')),
        __param(1, inversify_props_1.inject('ILogger')),
        __param(2, inversify_props_1.inject('ISettings'))
    ], CompanyService);
    return CompanyService;
}());
exports.CompanyService = CompanyService;
