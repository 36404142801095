"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Company = void 0;
var Company = /** @class */ (function () {
    function Company(init) {
        Object.assign(this, init);
    }
    return Company;
}());
exports.Company = Company;
