import { container } from 'inversify-props';
import {
	IContactMessageService, ContactMessageService,
	IContactReasonService, ContactReasonService,
} from '../services/messaging';

export default () => {
	container.bind<IContactMessageService>('IContactMessageService').to(ContactMessageService);
	container.bind<IContactReasonService>('IContactReasonService').to(ContactReasonService);
};
