"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeckVariation = void 0;
var DeckVariation = /** @class */ (function () {
    function DeckVariation(init) {
        Object.assign(this, init);
    }
    return DeckVariation;
}());
exports.DeckVariation = DeckVariation;
