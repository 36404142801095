"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Series = void 0;
var Series = /** @class */ (function () {
    function Series(init) {
        Object.assign(this, init);
    }
    return Series;
}());
exports.Series = Series;
