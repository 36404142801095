"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DeckColor = void 0;
/** Represents a single color associated with a deck's default image */
var DeckColor = /** @class */ (function () {
    function DeckColor() {
    }
    return DeckColor;
}());
exports.DeckColor = DeckColor;
