import { RouteConfig } from 'vue-router';

export default (): RouteConfig[] => {
	return [
		{ path: '', name: 'home', component: () => import('@client/views/common/Home.vue') },
		{ path: '404', name: '404', component: () => import('@client/views/common/404.vue') },
		{ path: 'about', name: 'about', component: () => import('@client/views/common/About.vue') },
		{ path: 'contact', name: 'contact', component: () => import('@client/views/common/Contact.vue') },
	];
};
