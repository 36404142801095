import { container } from 'inversify-props';
import {
	IDeckImageService, DeckImageService,
	IDeckVariationService, DeckVariationService,
	IDeckService, DeckService,
	IDeckShapeService, DeckShapeService,
	IDeckSizeService, DeckSizeService,
	IDeckSurfaceService, DeckSurfaceService,
	ISeriesService, SeriesService,
	ISeriesImageService, SeriesImageService,
	IDeckPriceService, DeckPriceService,
	IDeckNoteService, DeckNoteService,
	ITruckMountService, TruckMountService,
	ITechnologyService, TechnologyService,
	ITechnologyImageService, TechnologyImageService,
	ICatalogService, CatalogService,
	ICategoryService, CategoryService,
	IDeckFavoriteService, DeckFavoriteService,
} from '../services/products';

export default () => {
	container.bind<ITechnologyService>('ITechnologyService').to(TechnologyService);
	container.bind<ITechnologyImageService>('ITechnologyImageService').to(TechnologyImageService);
	container.bind<ITruckMountService>('ITruckMountService').to(TruckMountService);
	container.bind<IDeckImageService>('IDeckImageService').to(DeckImageService);
	container.bind<IDeckVariationService>('IDeckVariationService').to(DeckVariationService);
	container.bind<IDeckService>('IDeckService').to(DeckService);
	container.bind<IDeckNoteService>('IDeckNoteService').to(DeckNoteService);
	container.bind<IDeckShapeService>('IDeckShapeService').to(DeckShapeService);
	container.bind<IDeckSizeService>('IDeckSizeService').to(DeckSizeService);
	container.bind<IDeckSurfaceService>('IDeckSurfaceService').to(DeckSurfaceService);
	container.bind<ISeriesService>('ISeriesService').to(SeriesService);
	container.bind<ISeriesImageService>('ISeriesImageService').to(SeriesImageService);
	container.bind<IDeckPriceService>('IDeckPriceService').to(DeckPriceService);
	container.bind<IDeckFavoriteService>('IDeckFavoriteService').to(DeckFavoriteService);
	container.bind<ICatalogService>('ICatalogService').to(CatalogService);
	container.bind<ICategoryService>('ICategoryService').to(CategoryService);
};
