import { inject, injectable } from 'inversify-props';
import { IHttpClient, ILogger, ISettings } from '../common';
import { PagedResult, Season } from '../../models';
import { IBaseService } from '../BaseService';


export interface ISeasonService extends IBaseService<Season, number> {
	getMultiple(ids: number[]): Promise<Season[]>;
	autocomplete(crtieria: string): Promise<Season[]>;
}


@injectable()
export class SeasonService implements ISeasonService {

	private cache: Promise<Season[]>;

	constructor(
		@inject('IHttpClient') private httpClient: IHttpClient,
		@inject('ILogger') private logger: ILogger,
		@inject('ISettings') private settings: ISettings) { }

	public get(id: number): Promise<Season> {
		const url = `${this.settings.baseUrl}${this.settings.seasonBaseUrl}/${id}`;
		return this.httpClient.get<Season>(url);
	}

	public getMultiple(ids: number[]): Promise<Season[]> {
		const url = `${this.settings.baseUrl}${this.settings.seasonBaseUrl}/multiple/${ids.join(',')}`;
		return this.httpClient.get<Season[]>(url);
	}

	public page(pageIndex: number, pageSize: number): Promise<PagedResult<Season>> {
		const url = `${this.settings.baseUrl}${this.settings.seasonBaseUrl}/${pageIndex}/${pageSize}`;
		return this.httpClient.get<PagedResult<Season>>(url);
	}

	public autocomplete(criteria: string): Promise<Season[]> {
		const url = `${this.settings.baseUrl}${this.settings.seasonBaseUrl}/autocomplete/${criteria}`;
		return this.httpClient.get<Season[]>(url);
	}

	public list(): Promise<Season[]> {
		const url = `${this.settings.baseUrl}${this.settings.seasonBaseUrl}`;
		if (!this.cache) { this.cache = this.httpClient.get<Season[]>(url); }
		return this.cache;
	}

	public save(model: Season): Promise<Season> {
		this.cache = null; // INVALIDATE CACHE
		const payload = {
			name: model.name,
			id: model.id,
			sortBy: model.sortBy != null ? parseInt(model.sortBy.toString(), null) : 0,
		};
		let url = `${this.settings.baseUrl}${this.settings.seasonBaseUrl}`;
		if (model.id !== null && model.id !== undefined) {
			url += `/${model.id}`;
			return this.httpClient.put(url, payload);
		}
		return this.httpClient.post(url, payload);
	}

	public delete(id: number): Promise<any> {
		this.cache = null; // INVALIDATE CACHE
		const url = `${this.settings.baseUrl}${this.settings.seasonBaseUrl}/${id}`;
		return this.httpClient.delete(url);
	}

}
